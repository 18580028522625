.text {
    @include container;
    .copy {
        &.two-col {
            @include media(">=sm") {
                column-count: 2;
                page-break-inside: avoid;
                break-inside: avoid;
            }
        }
    }
}


.text-cta {
    padding: 60px 0;
    position: relative;
    background-color: $main-color-one;
    background-size: cover;
    background-position: center center;
    &:before {
        position: absolute;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($main-color-one, .85);
    }
    .superhead {
        position: relative;
        color: $white;
        text-align: center;
        font-size: rem(14);
        text-transform: uppercase;
        font-weight: 700;
    }
    .head {
        position: relative;
        color: $white;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: rem(22);
        line-height: rem(24);
        margin-bottom: 30px;
        &:before {
            position: absolute;
            display: block;
            content: '';
            width: 200px;
            height: 5px;
            bottom: -20px;
            right: 0;
            left: 0;
            background-color: $white;
            margin: 0 auto;
        }
        @include media(">=md") {
            font-size: rem(32);
            line-height: rem(34);
            margin-bottom: 60px;
            &:before {
                height: 10px;
                bottom: -30px;
            }
        }
    }
    .detail {
        position: relative;
        color: $white;
        text-align: center;
        font-size: rem(16);
        line-height: rem(18);
        @include media(">=md") {
            font-size: rem(20);
            line-height: rem(22);
        }
        a {
            color: $white;
            text-decoration: underline;
            &:hover {
                color: $lt-grey !important;
                text-decoration: none;
            }
        }
    }
    .buttons {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 10px;
        @include media(">=md") {
            margin-top: 20px;
        }
        a {
            border: 2px solid $white;
            color: $white;
            padding: rem(8) rem(5) rem(8) rem(10);
            font-size: rem(14);
            background-color: transparent;
            font-weight: 700;
            border-radius: 30px;
            position: relative;
            margin: 5px;
            padding-right: 35px;
            min-width: rem(200);
            text-decoration: none;
            &:after {
                display: inline-block;
                display: block;
                position: absolute;
                right: 8px;
                top: 8px;
                font-family: $icon-font;
                content: '\f054';
                margin-left: 5px;
                font-weight: 700;
                background-color: $white;
                color: $main-color-one;
                width: rem(20);
                height: rem(20);
                border-radius: 100%;
                padding-left: 7px;
                transition: all .3s ease;
            }
            &:hover {
                background-color: $white;
                color: $main-color-one !important;
                text-decoration: none;
                font-weight: 700;
                &:after {
                    padding-left: 11px;
                    transition: all .3s ease;
                }
            }
        }
    }
    &.alt {
        background-color: $white;
        &:before {
            background-color: rgba($white, .85);
        }
        .superhead {
            color: $dk-grey;
        }
        .head {
            color: $dk-grey;
            &:before {
                background-color: $dk-grey;
            }
        }
        .detail {
            color: $dk-grey;
            a {
                color: $dk-grey;
                text-decoration: underline;
                &:hover {
                    color: $main-color-one !important;
                    text-decoration: none;
                }
            }
        }
        .buttons {
            a {
                border-color: $dk-grey;
                color: $dk-grey;
                &:after {
                    background-color: $dk-grey;
                    color: $white;
                }
                &:hover {
                    background-color: $dk-grey;
                    color: $white !important;
                }
            }
        }
    }
    &.alt-two {
        background-color: $dk-grey;
        &:before {
            background-color: rgba($dk-grey, .85);
        }
        .superhead {
            color: $white;
        }
        .head {
            color: $white;
            &:before {
                background-color: $white;
            }
        }
        .detail {
            color: $white;
        }
        .buttons {
            a {
                border-color: $white;
                color: $white;
                &:after {
                    background-color: $white;
                    color: $dk-grey;
                }
                &:hover {
                    background-color: $white;
                    color: $dk-grey !important;
                }
            }
        }
    }
}