.video-embed {
    position: relative;
    height: 0;
    margin-bottom: 0;
	padding-bottom: 56.25%;
	overflow: hidden;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}