
// Slider styles

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-slide{
		display: flex;
		height: auto;
		align-items: center; //optional
		justify-content: center; //optional
	}
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 60px;
    width: 40px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: #000;
    top: calc(50% - 30px);
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
	z-index: 2;
    &:hover {
        outline: none;
        color: transparent;
        background-color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-family: $slick-font-family;
        font-size: 60px;
        font-weight: 700;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    &:focus {
        outline: none;
    }
}

.slick-prev {
    left: 5px;
    @include media(">=md") {
        left: 0;
    }
    [dir="rtl"] & {
        left: auto;
        right: 5px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: 5px;
    @include media(">=md") {
        right: 5px;
    }
    [dir="rtl"] & {
        left: 5px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    // margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -40px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 40px;
        width: 40px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 40px;
            width: 40px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '\f111';
                font-weight: 300;
                width: 40px;
                height: 40px;
                font-family: $slick-font-family;
                font-size: 32px;
                line-height: 20px;
                text-align: center;
                color: $slick-dot-color;
                // opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: 1;
            font-weight: 700;
        }
    }
}

// .carousel {
//     &.negative-bottom-margin {
//         margin-bottom: -60px;
//     }
// }

.carousel-hero {
	.slick-dots {
		bottom: -30px;
	}
}

.carousel-stats {
    .slick-prev,.slick-next {
        &:before {
            color: $main-color-two;
        }
    }
    .slick-prev {
        left: -12px !important;
    }
    .slick-next {
        right: -12px !important;
    }
}



.carousel {
    &.bg-one {
        background-color: $hilite-color-one;
    }
    @import 'components/carousels/carousel_hero';
    @import 'components/carousels/carousel_testimonial';
    @import 'components/carousels/carousel_top_stories';
    // @import 'components/carousels/carousel_stats';
}