@import 'navigation/main_nav_wrap';
@import 'navigation/navigation_logo';
@import 'navigation/navigation_utility';


.navigation {
	display: none;
	@include media(">=md") {
        position: relative;
        display: block;
        margin-top: 0
	}
}



ul.main-nav {
    list-style: none;
    padding-left: 0;
    position: relative;
    padding: 0;
    margin-bottom: 0;
	z-index: 1999;
	@include media(">=md") {
        text-align: right;
		margin-bottom: 0;
	}
	// TOP LEVEL NAV LINKS @ MOBILE
	li {
		position: relative;
		z-index: 2001;
		a {
			display: block;
			font-size: 20px;
			padding: 14px 15px;
			height: 54px;
			text-decoration: none;
			background-color: transparent;
			color: $white;
			font-family: $nav-font;
			font-weight: 300;
			transition: none;
			font-weight: 700;
			border-bottom: solid 1px $white;
			&:hover {
				color: $white;
			}
			@include media(">=md") {
				font-size: rem(16);
				// height: 2rem;
				padding: rem(10) rem(12) 0 rem(12) ;
				color: $white;
				border-bottom: none;
			}	
			@include media(">=lg") {
				font-size: .9375rem
			}	
		}
		&.toggle-main-sub {
			display: block;
			@include media(">=md") {
				display: none;
			}
		}
		ul {
			display: none;
			list-style: none;
			padding-left: 0;
			padding: 0;
			margin: 0;
			&.open {
				display: none;
			}	
			// SECOND LEVEL NAV LINKS @ MOBILE
			li {
				a {
					font-size: 18px;
					color: $dk-grey;
					font-weight: 700;
					background-color: $xlt-grey;
					border-color: $white;
					&:hover {
						color: $main-color-one;
					}
				}
				&.search-toggle {
					display: none;
				}
			}		
		}
		// TOP LEVEL NAV LINKS @ DESKTOP (li)
		@include media(">=md") {
			position: relative;
			text-align: left;
			display: inline-block;
			border-bottom: none;
			a {
				// border-bottom: none;
				height: rem(42);
			}
			// TOP LEVEL NAV LINKS HOVER @ DESKTOP (li)
			&:hover {
				// border-radius: 10px 10px 0 0;
				a {
					position: relative;
					color: $black;
					border-color: $white;
					position: relative;
					background-color: $lt-grey;
					z-index: 2001;
					// &:after {
					// 	display: block;
					// 	content: '';
					// 	background-color: $main-color-two;
					// 	height: 2px;
					// 	width: 100%;
					// 	position: absolute;
					// 	bottom: -2px;
					// 	left: 0;
					// }
				}
				// SECOND LEVEL NAV LINKS @ DESKTOP
				> ul {
					display: block;
					position: absolute;
					top: rem(42);
					width: rem(220);
					left: 0;
					// border: 2px solid $white;
					z-index: 2000;
					box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
					li {
						width: 100%;
						background-color: $lt-grey;
						display: block;
						border-bottom: none;
						text-transform: none;
						margin: 0;
						// border-radius: 0;
						border-bottom: 1px solid $xlt-grey;
						&:first-child {
							border-right: none;
							// border-radius: 0 10px 0 0;
							&:hover {
								a {
									color: $main-color-one;
								}
							} 
						}
						&:nth-last-child(2), &:last-child {
							// border-bottom: none;
							// border-radius: 0 0 10px 10px;
							a {
								text-align: left;
								&:hover {
									color: $main-color-one;
								}
							}
						}
						&:last-child {
							border-bottom: none;
						}
						&.search-toggle {   
							display: inline-block
						}
						a {
							height: auto;
							font-size: .875rem;
							padding: .3125rem 0 .3125rem .625rem;
							color: $black;
							background-color: transparent;
							font-weight: 400;
							border: none;
							&:after {
								display: none;
							}
							&:hover {
								background-color: $white !important;
								color: $main-color-one;
							}
						}
					}
				}
			}
			&:nth-last-child(2), &:last-child {
				a {
					// text-align: center;
				}
				&:hover {
					> ul {
						display: block;
						position: absolute;
						right: 0;
						left: auto;
						width: 220px;
						z-index: 2000;
						li {
							a {
								text-align: left;
								&:hover {
									// border-radius: 0;
								}
							}
							&:first-child {
								// border-radius: 10px 0 0 0;
								a {
									&:hover {
										// border-radius: 10px 0 0 0;
									}
								}
							}
							&:last-child {
								a {
									&:hover {
										// border-radius: 0 0 10px 10px;
									}
								}
							}
						}
					}
				}
			}
		}	
	}	
}


// MAIN NAV TABBING 
// .main-nav li {
// 	>ul {
// 		.show-menu {
// 			display: block;
// 			position: absolute;
// 			top: 4.0625rem;
// 			width: 15rem!important;
// 			z-index: 2000;
// 			li {
// 				background-color: #777;
// 				a {
// 					height: 2.5rem;
// 					padding: .4375rem 0 0 1rem;
// 					color: $white;
// 					width: 15rem!important;
// 					background-color: #777
// 				}
// 			}
// 		}
// 	}
// }

.mob-nav-wrap {
    background-color: $white;
	display: block;
	@include media(">=md") {
		display: none;
	}
	.mob-nav {
		display: block;
		height: 60px;
		background-color: $main-color-one
	}
}



a.nav-trigger {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    height: 60px;
    width: 56px;
    white-space: nowrap;
    color: $white;
    text-indent: -30px;
    padding-top: 16px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
	background-color: $dk-grey;
	&:hover {
		text-decoration: none;
	}
}


// HAMBURGER NAV TRIGGER
.nav-trigger {
	position: relative;
	span {
		position: absolute;
		width: 22px;
		height: 4px;
		background-color: $white;
		border-radius: 1.5px;
		left: 50%;
		top: 50%;
		bottom: auto;
		right: auto;
		-webkit-transform: translateX(-50%) translateY(-50%);
		-moz-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		-o-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		-webkit-transition: background-color .2s;
		-moz-transition: background-color .2s;
		transition: background-color .2s;
		&:after, &:before {
			position: absolute;
			width: 22px;
			height: 4px;
			background-color: $white;
			border-radius: 1.5px;
			content: '';
			top: 0;
			left: 0;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			-webkit-transition: -webkit-transform .2s;
			-moz-transition: -moz-transform .2s;
			transition: transform .2s;
		}
		&:before {
			-webkit-transform: translateY(-6px);
			-moz-transform: translateY(-6px);
			-ms-transform: translateY(-6px);
			-o-transform: translateY(-6px);
			transform: translateY(-6px);
		}
		&:after {
			-webkit-transform: translateY(6px);
			-moz-transform: translateY(6px);
			-ms-transform: translateY(6px);
			-o-transform: translateY(6px);
			transform: translateY(6px)
		}
	}
}

 
// HAMBURGER NAV TRIGGER WHEN OPEN
.nav-is-visible {
	.nav-trigger {
		span {
			background-color: transparent;
			&:before {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}		
			&:after {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transform: rotate(45deg);
			}	
		}
	} 
} 

// SECOND LEVEL NAV TOGGLE ICONS FOR MAIN NAV

ul {
	&.main-nav {
		li {
			.toggle-main-sub {
				background-color: $dk-grey;
				color: $white;
				cursor: pointer;
				display: inline-block;
				font-size: inherit;
				line-height: inherit;
				padding: 8px 15px;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 100;
				border-left: 1px solid $white;
				&:before {
					display: inline-block;
					font-family: $icon-font;
					font-weight: 700;
					font-size: 26px;
					content: '\f067';
					color: $white;
					transition: all .5s ease;
				}
				&.menu-visible {
					background-color: $xlt-grey;
					border-bottom: 1px solid $xlt-grey;
					&:before {
						color: $main-color-one;
						transform: rotate(-135deg);
						transition: all .5s ease;
					}			
					+ a {
						background-color: $lt-grey;
						color: $main-color-one;
						@include media(">=md") {
							background-color: transparent;
						}
					}		
				}
				@include media(">=md") {
					display: none;
				}
			}
		}
	}
}


// TABBING STYLES FOR KEYBOARD ONLY USERS

.main-nav {
	li {
		background-color: transparent;
		&.active-menu-container {
			background-color: $lt-grey;
			a {
				color: $black !important;
			}
			
		}
		a {
			&:focus {
				// background-color: $lt-grey;
				// color: $black;			
				// outline: none;
				// border: 2px solid red;
			}
		}
		> ul {
			&.show-menu {
				 display: block;
				 position: absolute;
				 top: rem(42);
				 left: 0;
				 width: rem(240) !important;
				 z-index: 2000;
				 background-color: transparent;
				 li {	
					 width: 100%;
					//  border-radius: 0;
					 a {
						 height: rem(34);
						 padding: .3125rem 0 .3125rem .625rem;	
						 color: $black;
						 background-color: $lt-grey !important;
						 text-align: left;		
						 font-size: rem(14);
						 &:focus {
							 background-color: $white !important;;				
							 
						 }
					 }
					 &:last-child {
						 a {
							 text-align: left;
						 }
					 }
				}
			}
		}
	}
}