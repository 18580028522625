@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}



#calendar {
    &.list-cal {
        ol.cal-header {
            display: none
        }
        ol {
            &.calendar-ol {
                display: block;
                padding: 20px;
                background-color: #fff;
                border: none;
                padding: 0;
                li {
                    &.day {
                        width: 100%;
                        border: none;
                        min-height: 20px;
                        float: none;
                        width: 100%;
                        border: none;
                        overflow: unset;
                        border-bottom: 2px dotted $cal-border-color;
                        margin-bottom: 10px;
                        .dayNum {
                            font-size: rem(16);
                            font-weight: 700;
                            padding-left: 0
                        }
                        ol {
                            display: flex;
                            flex-direction: column;
                            padding: 0;
                            li {
                                background-color: unset;
                                position: unset;
                                padding: rem(5) rem(5) rem(5) rem(30) !important;
                                height: auto;
                                line-height: initial;
                                white-space: unset;
                                color: inherit;
                                flex: 0 0 auto;
                                &:nth-child(even) {
                                    background: transparent url(../assets/images/line-texture-bg-overlay.png) 50%/7px auto repeat;
                                }
                                &.first-day {
                                    width: auto;
                                    overflow: unset;
                                    display: inline;
                                    a {
                                        color: $cal-linkColor !important;
                                        // width: auto;
                                        overflow: unset;
                                        // display: inline;
                                        // position: relative;
                                        &:hover {
                                            color: $cal-linkHoverColor !important;
                                        }
                                    }
                                }
                                &.multi-day {
                                    background-color: transparent;
                                    font-size: rem(16);
                                    height: auto;
                                    line-height: initial;
                                    white-space: unset;
                                    color: inherit;
                                    &.extension {
                                        color: inherit;
                                        a {
                                            color: $cal-linkColor !important;
                                            &:hover {
                                                color: $cal-linkHoverColor !important;
                                            }
                                        }
                                    } 
                                }
                                .time {
                                    display: block;
                                    &.listOnly {
                                        font-size: rem(12) !important;
                                        font-weight: 700;
                                    }
                                    &.calOnly {
                                        display: none !important;
                                    }
                                }
                                a {
                                    display: block;
                                    font-size: rem(18) !important;
                                    line-height: rem(20) !important;
                                    color: $cal-linkColor !important;
                                    position: relative;
                                    font-weight: 700;
                                    text-decoration: none;
                                    text-transform: uppercase;
                                    &:before {
                                        position: absolute;
                                        top: rem(0);
                                        left: rem(-20);
                                        font-family: $icon-font;
                                        content:'\f073';
                                        font-size: rem(18);
                                        font-weight: 300;
                                    }
                                    &:hover {
                                        color: $cal-linkHoverColor;
                                    }
                                }
                                .description {
                                    display: block;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    padding: 0;
                                    font-size: rem(12);
                                    margin: 0;
                                }
                            }
                        }
                        br {
                            display: none;
                        }
                        &.empty {
                            display: none;
                        }
                    }
                }     
                .otherMonth {
                    display: none;
                }
            }

        }
        .listOnly {
            display: inline;
        }
    } 
}



hr.listOnly {
    max-width: 100%;
    margin: rem(16) 0;
    border: 0;
    border-top: 1px dotted #1f1f1f;
}



#calendar.list-cal ol.calendar-ol li.day ol li div {
    padding: 10px 0;
    font-size: 14px;
}


