.main-nav-wrap {
    height: auto;
	position: relative;
	@include media(">=md") {
        position: absolute;
        top: rem(58);
        width: 100%;
	}
    .nav-container {
		// max-width: 100%;
        position: relative;
        @include container;
        // @include media(">=md") {
        //     max-width: $container-max-width;
        //     margin: 0 auto;
        //     padding-right: 15px;
        //     padding-left: 15px;
        // }
    }

	.grid-container {
		position: relative;
	}
}

