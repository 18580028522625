.nr-carousel-featured {
	background-color: $main-color-one;
	.slick-dots {
		bottom: -55px;
	}
	.slick-prev {
		top: 40px;
		left: 0;
		text-shadow: 0px 0px 10px rgba(0,0,0,0.75);
	} 
	.slick-next {
		top: 40px;
		right: 0;
		text-shadow: 0px 0px 10px rgba(0,0,0,0.75);
	} 
	.item {
		a {
			.inner-wrap {
				display: block;
				position: relative;
				z-index: 0;
				&:before {
					display: none;
				}
				.media-wrap {
					display: block;
					overflow: hidden;
					.inner {
						display: block;
						background-size: cover;
						background-position: center center;
						height: 200px;
						transition: all .5s ease-in-out;
						transform: scale(1);
						@include media(">=sm") {
							height: 350px;
						}
						@include media(">=sm") {
							height: 500px;
						}
					}
				}
				.message {
					display: block;
					position: relative;
					color: $white;
					text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
					padding: rem(20);
					font-size: rem(14);
					line-height: rem(16);
					font-weight: 700;
					z-index: 2;
					.date {
						display: inline;
						font-size: rem(14);
						text-transform: uppercase;
						margin: 0 0 10px 0;
						&:after {
							content: ' | ';
							font-size: rem(16);
						}
					}
					.category {
						display: inline;
						text-transform: uppercase;
						ul {
							@include reset-list;
							margin: 0 0 10px 0;
							padding: 0;
							display: inline-block;
							li {
								display: inline-block;
								text-align: left;
								font-size: rem(14);
								&:after {
									content:', '
								}
								&:last-child {
									&:after {
										content: '';
									}
								}
							}
						}
					}
					.title {
						display: block;
                        font-size: rem(20);
                        line-height: rem(22);
						text-transform: uppercase;
						margin-bottom: rem(15);
					}
					@include media(">=sm") {
						// background-color: rgba($black, .75);
						display: block;
						position: absolute;
						bottom: 0;
						right: 0;
						margin: 0 30px 30px 0;
						width: 80%;
						font-size: rem(18);
						line-height: rem(20);
						padding: 0 rem(30);
						padding-left: rem(60);
						text-align: right;
						// border-right: 10px solid $white;
						.title {
                            font-size: rem(32);
                            line-height: rem(34);
						}
					}
					.detail {
						display: block;
						@include multiLineEllipsis($lineHeight: 1.25rem, $lineCount: 2, $bgColor: $white);  
					}
					.bttn {
						display: inline-block;
						padding: 10px 20px;
						border: 2px solid $white;
						color: $white;
						text-transform: uppercase;
						font-size: rem(14);
						margin-top: 20px;
						box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
					}
				}
			}
			&:hover {
				.inner-wrap {
					.media-wrap {
						.inner {
							transition: all .5s ease-in-out;
							transform: scale(1.15);	
						}
					}
					.message { 
						.bttn {
							background-color: $white;
							color: $main-color-one;
							text-shadow: none;
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}

.nr-carousel-top-stories {
	.slick-dots {
		bottom: -15px;
	}
	&.slick-slider {
		margin: 0 -10px !important;
	}
	.slick-track {
		display: flex !important;
	}
	.slick-slide {
		height: auto;
	}
	.slick-prev {
		top: 40px;
		left: 10px;
		text-shadow: 0px 0px 10px rgba(0,0,0,0.75);
	}
	.slick-next {
		top: 40px;
		right: 10px;
		text-shadow: 0px 0px 10px rgba(0,0,0,0.75);
	}
	.slick-next:before, .slick-prev:before {
		font-size: rem(30);
		font-weight: 700;
	}
    .item {
		margin: 10px;
		border: 1px solid $nr-dk-grey;
		background-color: $white;
        a {
            display: block;
            // border: 2px solid $main-color-one;
            color: $main-color-one;
            height: 100%;
            .media {
                display: block;
                overflow: hidden;
				height: auto;
				.inner {
					display: block;
					height: 200px;
					background-size: cover;
					background-position: center center;
					transition: all .5s ease-in-out;
                    transform: scale(1);
				}
                img {
                    max-width: 100%;
                    transition: all .5s ease-in-out;
                    transform: scale(1);
                }
            }
            .editorial {
                display: block;
				padding: 10px;
                .date {
					display: inline;
					font-size: rem(12);
					font-family: $condensed-font;
					font-weight: 700;
					text-transform: uppercase;
					&:after {
						content: ' | ';
						font-size: rem(16);
					}
				}
				.category {
					display: inline;
					text-transform: uppercase;
					ul {
						@include reset-list;
						margin: 0;
						padding: 0;
						display: inline-block;
						li {
							display: inline-block;
							text-align: left;
							font-size: rem(12);
							font-family: $condensed-font;
							font-weight: 700;
							&:after {
								content:', '
							}
							&:last-child {
								&:after {
									content: '';
								}
							}
						}
					}
				}
                .title {
                    display: block;
                    font-weight: 700;
                    font-size: rem(18);
					margin-bottom: 10px;
					text-transform: uppercase;
					font-family: $condensed-font;
                }
                .detail {
                    display: block;
                    font-size: rem(14);
					line-height: rem(16);
					@include multiLineEllipsis($lineHeight: 1rem, $lineCount: 2, $bgColor: $white);  
                }
            }
            &:hover {
                color: $nr-color-two;
                text-decoration: none;
                // border: 2px solid $nr-color-two;
                .media {
                    .inner {
                        transition: all .5s ease-in-out;
                        transform: scale(1.15);
                    }
                }
			}
			&:focus {
				outline: dotted 2px $main-color-one;
			}
        }  
    }   
}