table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    thead {
        background-color: $main-color-one;
        color: $white;
        font-weight: 700;
    }
    tr {
        td {
            padding: 5px;
            font-size: rem(14);
            line-height: rem(16);
        }
    }
    tr:nth-of-type(odd) {
        background-color: rgba(0,0,0,.05);
    }
}

.table-wrap {
    overflow-x: auto;
}