
.inner-container {
    @include container;
}

.content-area {
    .inner-container {
        padding: 0 15px !important;
    }
}

.full-container {
    @include container;
}

.content-area {
    .full-container {
        padding-right: 0;
        padding-left: 0;
    }
}




.slab-container {  
    max-width: $container-max-width;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    @include media(">=90rem") {
        padding-right: 0;
        padding-left: 0;
    }
    .slab-row {
        @include media(">=md") {
            display: flex;
            margin-right: -15px;
            margin-left: -15px;
        }
    }
    .half {
        width: 100%;
        @include media(">=sm") {
            padding-right: 15px;
            padding-left: 15px;
        }
        @include media(">=lg") {
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

.carousel-container {
    max-width: $container-max-width;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    @include media(">=90rem") {
        padding-right: 0;
        padding-left: 0;
    }
    .carousel-row {
        @include media(">=md") {
            display: flex;
            margin-right: -15px;
            margin-left: -15px;
            border: solid 10px red;
        }
    }

}


.layout-container {
    max-width: $container-max-width;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    @include media(">=87.5rem") {
        padding-right: 0;
        padding-left: 0;
    }
}