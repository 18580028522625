.cal-grid {
    .filters {
        .filter-container {
            padding: 20px 0;
            @include media(">=md") {
                padding: 0 20px 0 0;
                height: 100%;
                border-right: 3px solid #e4e4e4;
            }
            .filters-title {
                display: none;
                @include media(">=md") {
                    display: block;
                    color: $black;
                    font-weight: 700;
                }
            }
            .mob-filters-trigger {
                display: block;
                text-align: center;
                @include media(">=md") {
                    display: none;
                }
                button {
                    &.mobile-control-button {
                        color: $cal-color-one;
                        font-weight: 700;
                        display: block;
                        padding: 5px;
                        width: 100%;
                        border: 2px solid $cal-color-one;
                        background-color: $white;
                        border-radius: $cal-border-radius;
                        &:before {
                            content:'Show ';
                        }
                        &:hover {
                            background-color: $cal-color-one;
                            color: $white;
                            text-decoration: none;
                        }
                    }    
                }
                &.active {
                    button {
                        &.mobile-control-button {
                            background-color: $cal-color-one;
                            color: $white;
                            &:before {
                                content:'Hide ';
                            }
                        }
                    } 
                }
            }
            .filter-title {
                font-size: rem(20);
                font-weight: 700;
                border-bottom: 1px dotted $black;
                margin-bottom: 20px;
            }
            ol {
                &.filter-list {
                    list-style: none;
                    margin: 10px 0 0 0;
                    padding: 0;
                    display: none;
                    @include media(">=md") {
                        display: block;
                        margin: 0;
                    }
                    li {
                        margin-bottom: 3px;
                        a {
                            display: block;
                            position: relative;
                            font-size: rem(14);  
                            padding: 3px 3px 3px 10px;
                            border: #000 solid 0px;
                            border-radius: $cal-border-radius;
                            transition: all 0.2s ease;
                            text-decoration: none;
                            color: $cal-filterColor;
                            background-color: $cal-filterBgColor; 
                            &:hover {
                                background-color: $cal-filterBgColorHover;
                                color: $cal-filterColorHover;
                                padding-left: 15px;
                                transition: all 0.2s ease;
                            }
                            &.active {
                                background-color: $cal-filterBgColorActive;
                                color: $cal-filterColorActive;
                                padding-left: 15px;
                                transition: all 0.2s ease;
                                &:hover {
                                    color: $cal-filterColorActive;
                                }
                                &:after {
                                    position: absolute;
                                    top: 4px;
                                    right: 10px;
                                    font-family: $icon-font;
                                    font-weight: 700;
                                    content: '\f00d';
                                }
                            }
                        }
                        ol {
                            padding: 0;
                            margin-left: 20px;
                            list-style: none;
                            a {
                                &:hover {
                                    color: $cal-filterColorHover;
                                }
                                &.active {
                                    background-color: lighten($cal-filterBgColorActive, 10%);
                                }
                            }
                        }
                    } 
                }
            }
        }
    }
}



.filter-container .filters li a {

}

.filter-container .filters li ol li a {
    padding-left: 25px;
}

@media (min-width: 768px) {
    .filter-container .filters li a {
        font-size: 12px
    }
}

.filter-container .filters li a.active {
    border: #215732 solid 1px;
    // border-radius: 30px;
    background-color: #215732;
    color: #fff
}

.filter-container .filters li a.active:hover {
    background-color: #002E5D;
    color: #fff;
    padding-left: 15px
}

// MOBILE FILTERS