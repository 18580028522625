.stats {
    .inner {
        @include media(">=xs") {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .item {
            width: 100%;
            margin-bottom: 20px;
            @include media(">=sm") { 
                width: 50%;
            }
            @include media(">md") {
                width: auto;
                margin-bottom: 0;
                max-width: 20%;
                padding-right: 20px;
                &:last-child {
                    padding-right: 0;
                }
            }

            .stat {
                color: $main-color-one;
                font-family: $header-font;
                font-size: rem(44);
                font-weight: 900;
                text-align: center;
                @include media(">lg") {
                    font-size: rem(64);
                }
            }
            .detail {
                text-align: center;
                font-size: rem(20);
                line-height: rem(18);
                color: $grey;
                font-weight: 900;
            }
        }
    }
}