ul.utility-nav {
    list-style: none;
    padding-left: 0;
    list-style: none;
    padding: 0;
    text-align: center;
    margin: 0;
    position: relative;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid $white;
    @include media(">=md") {
        border-bottom: none;
        padding: 0;
        text-align: right;
        padding: 0;
        position: absolute;
        top: rem(-54);
        right: rem(300);
    }
    li {
        list-style: none;
        padding-left: 0;
        position: relative;
        display: inline-block;
        &:nth-child(2) {
            &:after {
                display: none;
            }
        }
        @include media(">=420px") {
            &:nth-child(2) {
                &:after {
                    display: block;
                }
            }
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        a {
            padding: 8px 12px;
            text-transform: none;
            background-color: #724149;            
            color: 
            $white;
            font-size: 16px;
            display: block;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            z-index: 1;
            &:before {
                content: '';
                display: block;
                background-color: $white;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 100%;
                left: 0;
                transition: all .3s ease-in-out;
                width: 100%;
                z-index: -1;
            }
            &:hover {
                text-decoration: none;
                &:before {
                    top: 0;
                }
            }
            @include media(">=md") {
                display: flex;
                align-items: center;
                justify-content: center;
                height: rem(50);
                width: rem(100);
                font-size: rem(14);
                border: none;
                text-decoration: none;
                font-weight: 700;
                padding: 0;
                text-decoration: none;
                border: none;
                &:hover {
                    color: $main-color-one;
                }
                &:after {
                    display: none;
                }
                .linktext {
                    display: block;
                }
            }
        }
        @include media(">=md") {
            &:after {
                display: none;
            }
            &:nth-child(2) {
                &:after {
                    display: none;
                }
            }
        }
    }
}



