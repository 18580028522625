

	.article-head {
		margin: rem(30) 0;
		.author {
			display: block;
			font-size: rem(22);
			text-transform: uppercase;
			font-family: $condensed-font;
			font-weight: 700;
		}
		.date {
			display: inline;
			font-size: rem(16);
			font-family: $condensed-font;
			font-weight: 700;
			text-transform: uppercase;
			&:after {
				content: ' | ';
				font-size: rem(18);
			}
		}
		.category {
			display: inline;
			text-transform: uppercase;
			ul {
				@include reset-list;
				margin: 0;
				padding: 0;
				display: inline-block;
				li {
					display: inline-block;
					text-align: left;
					font-size: rem(14);
					font-family: $condensed-font;
					font-weight: 700;
					margin: 0;
					padding: 0;
					border-bottom: none;
					&:after {
						content:', '
					}
					&:last-child {
						&:after {
							content: '';
						}
					}
				}
			}
		}
	}
	
	.nr-hero {
		img {
			width: 100%;
			max-width: 100%;
		}
	}
	// .nr-article {
// 	.article-head {
// 		margin-bottom: 30px;
// 		.author {
// 			font-size: rem(22);
// 			text-transform: uppercase;
// 			font-family: $condensed-font;
// 			font-weight: 700;
// 		}
// 		.date {
// 			display: inline;
// 			font-size: rem(16);
// 			font-family: $condensed-font;
// 			font-weight: 700;
// 			text-transform: uppercase;
// 			&:after {
// 				content: ' | ';
// 				font-size: rem(18);
// 			}
// 		}
// 		.category {
// 			display: inline;
// 			text-transform: uppercase;
// 			ul {
// 				@include reset-list;
// 				margin: 0;
// 				padding: 0;
// 				display: inline-block;
// 				li {
// 					display: inline-block;
// 					text-align: left;
// 					font-size: rem(14);
// 					font-family: $condensed-font;
// 					font-weight: 700;
// 					margin: 0;
// 					padding: 0;
// 					border-bottom: none;
// 					&:after {
// 						content:', '
// 					}
// 					&:last-child {
// 						&:after {
// 							content: '';
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.hero {
// 		margin-bottom: 30px;	
// 		.inner {
// 			height: 400px;
// 			background-size: cover;
// 			background-position: center center;
// 		}
// 	}
// 	figure {
// 		width: 300px;
// 		&.right {
// 			float: right;
// 			margin: 0 0 20px 20px;
// 		}
// 		&.left {
// 			float: left;
// 			margin: 0 20px 20px 0;
// 		}
// 		img {
// 			width: 100%;
// 			height: auto;
// 		}
// 		figcaption {
// 			text-align: center;
// 			font-size: rem(12);
// 			line-height: rem(14);
// 			background-color: #f4f4f4;
// 			padding: 5px;
// 			margin-top: -5px;
// 		}
// 	}
// }

// .nr-hero, .lp-hero {
// 	@include container;
// 	img {
// 		width: 100%;
// 	}
// }