.event-listing {
    @include container;
    .event-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            margin-bottom: 20px;
            display: block;
            page-break-inside: avoid;
            break-inside: avoid;
            a {
                display: flex;
                width: 100%;
                text-decoration: none;
                .date {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border: 3px solid $main-color-one;
                    width: rem(60);
                    min-height: 60px;
                    padding: 10px;
                    text-align: center;
                    color: $main-color-two;
                    font-size: rem(20);
                    line-height: rem(20);
                    font-family: $header-font;
                    font-weight: 700;   
                    span {
                        display: block;
                        text-align: center;
                        color: $main-color-two;
                        font-size: rem(30);
                        line-height: rem(30);
                        font-weight: 700;  
                    }
                    &.multi-day {
                        span {
                            font-size: rem(10);
                            line-height: rem(10);
                        }
                    }
                }
                .details {
                    display: block;
                    padding-left: 20px;
                    .title {
                        display: block;
                        text-transform: uppercase;
                        font-size: rem(18);
                        line-height: rem(20);
                        font-weight: 700;
                        color: $main-color-two;
                        // margin-bottom: 10px;
                        @include multiLineEllipsis($lineHeight: 1.25rem, $lineCount: 1, $bgColor: $white);  
                        text-decoration: underline;
                    }
                    .description {
                        display: block;
                        font-size: rem(14);
                        @include multiLineEllipsis($lineHeight: 1rem, $lineCount: 2, $bgColor: $white);  
                        margin: 5px 0;
    
                    }
                    .time {
                        display: block;
                        font-style: italic;
                        font-size: rem(14);
                        line-height: rem(14);
                    }
                }
                &:hover {
                    text-decoration: none;
                    .date {
                        text-decoration: none;
                        background-color: $main-color-one;
                        color: $white;
                        span {
                            color: $white;
                        }
                    }
                    .details {
                        .title {
                            color: $main-color-one;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }  
        &.sm {
            li {
                margin-bottom: 10px;
                a {
                    display: flex;
                    width: 100%;
                    text-decoration: none;
                    .date {
                        width: rem(40);
                        min-height: 30px;
                        padding: 10px;
                        border: none;
                        border-right: solid 1px $main-color-one;
                        font-size: rem(14);
                            line-height: rem(14);
                        span {
                            display: block;
                            text-align: center;
                            color: $main-color-two;
                            font-size: rem(18);
                            line-height: rem(18);
                            font-weight: 700;  
                        }
                    }
                    .details {
                        padding-left: 10px;
                        .title {
                            font-size: rem(14);
                            line-height: rem(18);
                        }
                        .description {
                            font-size: rem(12);
                            line-height: rem(14);
        
                        }
                        .time {
                            display: block;
                            font-style: italic;
                            font-size: rem(12);
                            line-height: rem(12);
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        .date {
                            text-decoration: none;
                            background-color: $main-color-one;
                            color: $white;
                            span {
                                color: $white;
                            }
                        }
                        .details {
                            .title {
                                color: $main-color-one;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }  
        }
        &.two-col {
            @include media(">=md") {
                column-count: 2;
            }
        }
    }
    .all-events {
        border-top: 1px dotted $main-color-one;
        margin-top: 20px;
        padding-top: 20px;
        text-align: center;
        a {
            display: inline-block;
            border: 2px solid $main-color-one;
            padding: 10px 20px;
            color: $main-color-one;
            font-size: rem(16);
            border-radius: 30px;
            font-weight: 700;
            margin-top: 40px;
            width: auto;
            text-align: center;
            background-color: transparent;
            text-decoration: none !important;
            text-transform: uppercase;
            &:after {
                font-family: $icon-font;
                content: '\f054';
                margin-left: 5px;
            }
            &:hover {
                color: $white;
                background-color: $main-color-one;
                text-decoration: none;
            }
        }
    }
 }