.html-content {
    ul {
        @include reset-list;
        li {
            padding-left: 20px;
            position: relative;
            &:before {
                // position: absolute;
                // top: 0;
                // left: 0;
                font-family: $icon-font;
                content: '\f054';
                font-weight: 700;
                color: $main-color-one;
                padding-right: 10px;
            }
        }
    }
}

.image-teaser, .card-teaser, .text-cta, .callout-blocks {
    .html-content {
        ul {
            li {
                &:before {
                    color: $white;
                }
            }
        }
    }
    &.alt {
        .html-content {
            ul {
                li {
                    &:before {
                        color: $main-color-one;
                    }
                }
            }
        }
    }
}
.callout-text {
    .html-content {
        ul {
            li {
                &:before {
                    color: $text-color;
                }
            }
        }
    }
    &.alt {
        .html-content {
            ul {
                li {
                    &:before {
                        color: $white;
                    }
                }
            }
        }
    }
}