.visit-tour {
    background-color: $xdk-grey;  
    position: relative;
    @include media(">md") {
        display: flex;
        min-height: rem(300);
    }
    .visit {
        padding: 15px;
        @include media(">md") {
            flex: 0 0 50%;
            padding: 80px 0;
        }
        .inner {
            display: block;
            padding: 20px;
            // margin: 0 0 0 auto;
            text-align: center;
            border: 5px solid rgba($white, .2);
            @include media(">md") {
                display: flex;
                flex-direction: column;
                max-width: rem(690);
                margin-right: 30px;
                margin-left: 15px;
            }
            @include media(">=90rem") {
                margin-left: auto;
            }
            .superhead {
                text-align: center;
                color: $white;
                text-transform: uppercase;
                font-size: rem(28);
                font-weight: 700;
                @include media(">=md") {
                    font-size: rem(40);
                }
            }
            .subhead {
                color: $white;
                text-align: center;
            }
            .button {
                text-align: center;
                a {
                    display: inline-block;
                    border: 2px solid $white;
                    padding: 10px 20px;
                    color: $white;
                    font-size: rem(16);
                    border-radius: 30px;
                    font-weight: 700;
                    margin-top: 20px;
                    width: auto;
                    text-align: center;
                    background-color: transparent;
                    text-decoration: none !important;
                    text-transform: uppercase;
                    &:after {
                        font-family: $icon-font;
                        content: '\f054';
                        margin-left: 5px;
                    }
                    &:hover {
                        color: $main-color-one;
                        background-color: $white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .tour {
        width: 100%;
        position: relative;
        z-index: 1;
        background-image: url(../images/lp-hero.png);
        background-size: cover;
        padding: 15px;
        background-color: $white;
        @include media(">=md") {
            flex: 0 0 50%;
            margin-top: 0;
            padding: 80px 0;
        }   
        .cta {
            @include media(">=md") {
                max-width: rem(720);
                padding: 0 0 0 30px;
            }
            .inner {
                border: 5px solid $white;
                padding: 20px;
                background-color: rgba($white, .65);
                -webkit-backdrop-filter: blur(5x);
                backdrop-filter: blur(5px);
                text-align: center;
                display: block;
                @include media(">=md") {
                    display: flex;
                    flex-direction: column;
                    margin-right: 15px;
                }
                @include media(">=90rem") {
                    margin-right: 0;
                }
                .superhead {
                    text-align: center;
                    color: $main-color-one;
                    text-transform: uppercase;
                    font-size: rem(28);
                    font-weight: 700;
                    @include media(">=md") {
                        font-size: rem(40);
                    }
                }
                .subhead {
                    color: $main-color-one;
                    text-align: center;
                }
                .button {
                    text-align: center;
                    a {
                        display: inline-block;
                        border: 2px solid $main-color-one;
                        padding: 10px 20px;
                        color: $main-color-one;
                        font-size: rem(16);
                        border-radius: 30px;
                        font-weight: 700;
                        margin-top: 20px;
                        width: auto;
                        text-align: center;
                        background-color: transparent;
                        text-decoration: none !important;
                        text-transform: uppercase;
                        &:after {
                            font-family: $icon-font;
                            content: '\f054';
                            margin-left: 5px;
                        }
                        &:hover {
                            color: $white;
                            background-color: $main-color-one;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}