.body {
    overflow-x: hidden;
}


.header {
    position: relative;
    background-image: none;
    height: auto;
    z-index: 2000;
    background-color: $main-color-one;
    box-shadow: none;
}

@media (min-width:62rem) {
    .header {
        height: rem(100);
    }
}

.header .mob {
    display: block;
    padding-top: 20px;
    width: 68px;
    height: 50px;
    float: right
}

@media (min-width:62rem) {
    .header .mob {
        display: none
    }
}

.header .mob .fa {
    font-size: 30px;
    color: $main-color-one
}
