a {
	&.nr-bttn {
		display: inline-block;
		padding: 10px 20px;
		color: $main-color-one;
		border: 2px solid $main-color-one;
		font-size: rem(14);
		font-family: $condensed-font;
		text-transform: uppercase;
		font-weight: 700;
		&:hover {
			color: $white;
			background-color: $main-color-one;
			text-decoration: none;
		}
		&:focus {
			outline: 2px dotted red;
		}
		&:after {
			font-family: $icon-font;
			content: '\f324';
			font-weight: 700;
			padding-left: 5px;
		}
	}
}