.nr-grid {
    .nr-categories {
        .categories-container {
            padding: 20px 0;
            @include media(">=md") {
                padding: 60px 0 0 20px;
                height: 100%;
                border-left: 3px solid #e4e4e4;
            }
            .categories-title {
                font-family: $header-font;
                text-transform: uppercase;
                font-size: rem(26);
                display: none;
                @include media(">=md") {
                    display: block;
                    color: $black;
                    font-weight: 700;
                }
            }
            .mob-categories-trigger {
                display: block;
                text-align: center;
                @include media(">=md") {
                    display: none;
                }
                a {
                    color: $main-color-one;
                    font-weight: 700;
                    display: block;
                    padding: 5px;
                    width: 100%;
                    border: 2px solid $main-color-one;
                    background-color: $white;
                    border-radius: $nr-border-radius;
                    &:before {
                        content:'Show ';
                    }
                    &:hover {
                        background-color: $main-color-one;
                        color: $white;
                        text-decoration: none;
                    }
                }
                &.active {
                    a {
                        background-color: $main-color-one;
                        color: $white;
                        &:before {
                            content:'Hide ';
                        }
                    } 
                }
            }
            .categories-title {
                font-size: rem(20);
                font-weight: 700;
                border-bottom: 1px dotted $black;
                margin-bottom: 20px;
            }
            ol {
                &.categories-list {
                    list-style: none;
                    margin: 10px 0 0 0;
                    padding: 0;
                    display: none;
                    @include media(">=md") {
                        display: block;
                        margin: 0;
                    }
                    li {
                        margin-bottom: 3px;
                        a {
                            display: block;
                            position: relative;
                            font-size: rem(14);  
                            padding: 3px 3px 3px 10px;
                            border: #000 solid 0px;
                            border-radius: $nr-border-radius;
                            transition: all 0.2s ease;
                            text-decoration: none;
                            color: $nr-categoryColor;
                            background-color: $nr-categoryBgColor; 
                            &:hover {
                                background-color: $nr-categoryBgColorHover;
                                color: $nr-categoryColorHover;
                                padding-left: 15px;
                                transition: all 0.2s ease;
                            }
                            &.active {
                                background-color: $nr-categoryBgColorActive;
                                color: $nr-categoryColorActive;
                                padding-left: 15px;
                                transition: all 0.2s ease;
                                &:hover {
                                    color: $nr-categoryColorActive;
                                }
                                &:after {
                                    position: absolute;
                                    top: 4px;
                                    right: 10px;
                                    font-family: $icon-font;
                                    font-weight: 700;
                                    content: '\f00d';
                                }
                            }
                        }
                        ol {
                            padding: 0;
                            margin-left: 20px;
                            list-style: none;
                            a {
                                &:hover {
                                    color: $nr-categoryColorHover;
                                }
                                &.active {
                                    background-color: lighten($nr-categoryBgColorActive, 10%);
                                }
                            }
                        }
                    } 
                }
            }
        }
    }
}



.categories-container .categories li a {

}

.categories-container .categories li ol li a {
    padding-left: 25px;
}

@media (min-width: 768px) {
    .categories-container .categories li a {
        font-size: 12px
    }
}

.categories-container .categories li a.active {
    border: #215732 solid 1px;
    // border-radius: 30px;
    background-color: #215732;
    color: #fff
}

.categories-container .categories li a.active:hover {
    background-color: #002E5D;
    color: #fff;
    padding-left: 15px
}

// MOBILE FILTERS