.card-grid {
    @include media(">=sm") {
        display: flex;
    }
    .item {
        flex-grow: 1;
        flex-basis: 0;
        width: auto;
        position: relative;
        border: 2px solid $main-color-two;
        margin-bottom: 20px;
        padding-bottom: rem(60);
        @include media(">=sm") {
            margin-right: 20px;
            margin-bottom: 0;
            &:last-child {
                margin-right: 0;
            }
        }
        .media {
            position: relative;
            min-height: 200px;
            display: block;
            overflow: hidden;
            z-index: 2;
            img {
                position: absolute;
                max-width: none;
                // width: auto;
                transform: translate(-50%,-50%);
                height: auto;
                min-height: 100%;
                // min-height: 100%;
                right: auto;
                left: 50%;
                top: 50%;
                width: 140%;
                min-width: 100%;
                @include media(">=28.125rem") {
                    width: 100%;
                    min-width: 100%;
                }
                @include media(">=sm") {
                    width: 180%;
                    min-width: 100%;
                }
                @include media(">=md") {
                    width: 140%;
                    min-width: 100%;
                }
                @include media(">=lg") {
                    width: 110%;
                    min-width: 100%;
                }
            }
        }
        .detail {
            position: relative;
            padding: 20px;
            font-size: rem(14);
            line-height: rem(16);
            font-weight: 700;
            h2 {
                font-size: rem(20);
                line-height: rem(22);
            }
            a {
                color: $dk-grey;
                text-decoration: underline;
                &:hover {
                    color: $dk-grey;
                    text-decoration: none;
                }
            }
        }
        .button {
            text-align: center;
            margin-bottom: 20px;
            position: absolute;
            bottom: 0;
            width: 100%;
            a {
                display: inline-block;
                border: 2px solid $dk-grey;
                color: $dk-grey;
                padding: rem(8) rem(5) rem(8) rem(10);
                font-size: rem(14);
                background-color: transparent;
                font-weight: 700;
                border-radius: 30px;
                position: relative;
                margin: 20px 5px 0 5px;
                text-decoration: none;
                &:after {
                    display: inline-block;
                    // position: absolute;
                    right: 10px;
                    font-family: $icon-font;
                    content: '\f054';
                    margin-left: 5px;
                    font-weight: 700;
                    background-color: $dk-grey;
                    color: $white;
                    width: rem(20);
                    height: rem(20);
                    border-radius: 100%;
                    padding-left: 5px;
                    transition: all .3s ease;
                }
                &:hover {
                    background-color: $dk-grey;
                    color: $white !important;
                    text-decoration: none;
                    font-weight: 700;
                    &:after {
                        padding-left: 11px;
                        transition: all .3s ease;
                    }
                    
                }
            }
        }
    }
}