footer {
    background-color: $dk-grey;
    background-image: url(../images/bg-stripe.svg);
    background-size: cover;
    background-position: top center;
    padding: 20px 0;
    @include media(">=sm") {
        padding: 60px 0;
    }
    .inner {
        @include container;
        .links {
            ul {
                column-count: 1;
                @include reset-list;
                @include media(">=xs") {
                    column-count: 2;
                }
                @include media(">=md") {
                    column-count: 3;
                }
                @include media(">=lg") {
                    column-count: 5;
                }
                li {
                    display: block;
                    -webkit-column-break-inside: avoid;
                    page-break-inside: avoid;
                    break-inside: avoid;
                    a {
                        display: block;
                        color: $white;
                        text-decoration: none;
                        padding: 5px 10px;
                        font-size: rem(13);
                        line-height: rem(16)
;                        border: 2px solid transparent;
                        &:hover {
                            border-color: $white;
                        }
                    }
                }
            }
        }
        .bottom {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px dotted $grey;
            @include media(">=md") {
                display: flex;
                justify-content: space-between;
            }
            .logo {
                text-align: center;
                @include media(">=md") {
                    text-align: left;
                    flex: 0 0 33%;
                    width: 33%;
                }
                img {
                    width: 300px;
                    max-width: 100%;
                }
            }
            .location {
                color: $white;
                text-align: center;
                margin-top: 20px;
                @include media(">=md") {
                    margin-top: 0;
                    flex: 0 0 33%;
                    width: 33%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }
            }
            .social-icons {
                margin-top: 20px;
                text-align: center;
                @include media(">=md") {
                    margin-top: 0;
                    flex: 0 0 33%;
                    width: 33%;
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                }
                ul {
                    @include reset-list;
                    display: inline-block;
                    @include media(">=md") {
                        text-align: right;
                        width: 100%;
                    }
                    li {
                        display: inline-block;
                        text-align: center;
                        a {
                            display: inline-block;
                            text-align: center;
                            color: $white;
                            font-size: 16px;
                            width: 36px;
                            height: 36px;
                            padding-top: 4px;
                            border: 2px solid $white;
                            border-radius: 100%;
                            text-decoration: none;
                            &:hover {
                                color: $main-color-one;
                                background-color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}