.logo-wrap {
	max-width: 100%;
	position: relative;
	z-index: 2000;
	@include media(">=md") {
		max-width: $container-max-width;
		margin: 0 auto;
	}
	a {
		&.logo {
			position: absolute;
			top: 15px;
			left: 15px;
			width: 240px;
			@include media(">=xs") {
				width: 260px;
			}
			@include media(">=md") {
				top: 20px;
				width: 250px;
			}
			@include media(">=83.625rem") {
				width: 450px;
				top: 20px;
			}
			@include media(">=91.875rem") {
				left: 0;
			}
			img {
				margin-top: 0;
				width: 100%;
				max-width: 100%;
				transition: width .15s ease-in-out!important
			}
		}
	}
}
