figure {
    display: inline-block;
    width: 400px;
    border: solid 1px $black;
    margin: 0;
    &.right {
        @include media(">=xs") {
            float: right;
            margin: 0 0 20px 20px;
        }
    }
    &.left {
        @include media(">=xs") {
            float: left;
            margin: 0 20px 20px 0;
        }
    }
    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
    figcaption {
        text-align: center;
        font-size: rem(12);
        line-height: rem(14);
        background-color: #f4f4f4;
        padding: 10px 5px;
    }
}