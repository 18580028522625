button.mobile-control-button {
    width: 100%;
}

.calendar-controls {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 3px solid $cal-color-two;
    text-align: center;
    .range-group {
        margin-bottom: 20px;
        @include media(">=md") {
            width: 50%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            min-height: 0;
            min-width: 0;
            order: 2;
            text-align: right;
            margin-bottom: 0;
        }
        .range {
            .viewby {
                font-size: rem(12);
                font-weight: 700;
                display: block;
                @include media(">=md") {
                    display: inline;
                }
            }
            button {
                border-radius: $cal-border-radius;
                border: 2px solid $cal-color-one;
                color: $cal-color-one;
                background-color: $white;
                font-size: rem(14);
                font-weight: 700;
                padding: 8px 12px;
                &:hover {
                    cursor: pointer;
                    color: $white;
                    background-color: $cal-color-one;
                    background-image: linear-gradient(#ca3139,#b01718);
                    border-color: $cal-color-one;
                    background-image: none;
                }
            }
            .active-range,
            .active-range:hover,
            .active-range:active,
            .active-range:focus {
                color: $white;
                background: $cal-color-one;
                border-color: $cal-color-one !important;
                background-image: none;
            }
        }
    }
    .controls-group {
        @include media(">=md") {
            width: 50%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            min-height: 0;
            min-width: 0;
            order: 1;
        }
        .controls {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @include media(">=md") {
                justify-content: flex-start;
                flex-direction: row;
            }
            .prev-next-controls {
                display: flex;
                order: 2;
                @include media(">=md") {
                    order: 1;
                }
                .previousPeriod, .nextPeriod {
                    border: 2px solid $cal-color-one;
                    color: $cal-color-one;
                    background-color: transparent;
                    border-radius: $cal-border-radius;
                    text-decoration: none;
                    padding: 5px;
                    &:after {
                        font-size: rem(18);
                        padding: 3px;
                        font-weight: 700;
                    }
                    &:hover {
                        text-decoration: none;
                        background-color: $cal-color-one;
                        color: $white;
                    }
                }
                .previousPeriod {
                    margin-right: 5px;
                    &:after {
                        font-family: $icon-font;
                        content: '\f060';
                    }
                }
                .nextPeriod {
                    &:after {
                        font-family: $icon-font;
                        content: '\f061';
                    }
                }
            }
            .thisPeriod {
                padding: 0 rem(10);
                font-size: rem(20);
                font-weight: 700;
                order: 1;
                @include media(">=md") {
                    order: 2;
                }
            }
        }
    }
}





// #calendar .btn-xs,
// #calendar a.btn-xs {
//     font-size: 0.6rem;
// }

