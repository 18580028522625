.accordion-wrapper {
	.accordion-container {
		width: 100%;
		clear: both;
		border-bottom: solid 2px $main-color-one;
		&:first-child {
			border-top: solid 2px $main-color-one;
		}
	}
}

a.accordion-toggle {
	position: relative;
	display: block;
	padding: 5px 5px 5px 10px;
	font-size: rem(16);
	font-weight: 700;
	color: $main-color-one;
	text-decoration: none !important;
	border-right: 2px solid $main-color-one;
	border-left: 2px solid $main-color-one;
	// @include stripe-red;
	&:focus {
		outline: none;
	}
	@include media(">=md") {
		font-size: rem(18);
	}
	&.open{	
		color: #fff;
		background-color: $main-color-one;
		&:hover {
			color: #fff;
		}
		span.toggle-icon {
			color: $white;
		}
		+.accordion-content {
			// border-top: solid 1px $main-color-two;
			border-right: solid 2px $main-color-one;
			border-left: solid 2px $main-color-one;
		}
	}
	&:hover, &:active {
		color:$black;
	}
}
.accordion-toggle span.toggle-icon {
	position: absolute;
	top: 4px;
	right: 10px;
	color: $main-color-one;
}
.accordion-content {
	display: none;
	padding: 10px;
	overflow: auto;
	transition: none;
}
.accordion-content img {
	display: block;
	float: left;
	margin: 0 15px 10px 0;
	max-width: 100%;
	height: auto;
}

/* media query for mobile */
@media (max-width: 767px) {
	.content {
		width: auto;
	}
	.accordion-content {
		overflow: inherit;
	}
}