// body {
//     background-color: $white;
//     margin: 0;
// }


*,
*::before,
*::after {
  box-sizing: border-box; 
}

img {
    display: block;
}

body {
    background-color: $white;
    margin: 0;
    line-height: 1.5;
}


[tabindex="-1"]:focus {
    outline: 0!important
}


dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

fieldset {
    border: none;
    padding: 0;
}


.page-content {
    background-color: $white;
    .page-inner {
        @include container;
        @include media(">=md") {
            display: flex;
        }
        .sidebar {
            padding: 20px 0 0 0;
            @include media(">=md") {
                flex: 0 0 350px;
                -ms-flex: 0 0 350px;
                width: 350px;
                border-right: 2px solid $lt-grey;
                padding: 40px 20px 40px 0;
            }
        }
        .content-area {
            padding-bottom: 40px;
            @include media(">=md") {
                padding: 40px 0 40px 20px;
                flex: 1;
            }
        }
    }
}



.pagetitle {
    background-color: $dk-grey; 
    background-image: url(../images/bg-stripe.svg);
    background-size: cover;
    background-position: center center;
    padding: 20px 0;
	h1 {
		margin: 0;
		padding: 0;
		color: $white;
		font-family: $header-font;
		font-size: rem(30);
        line-height: rem(30);
    }
    &.lp {
        margin-bottom: 0;
    }
}


.sidebar-social {
    padding-bottom: 30px;
    ul {
        @include reset-list;
        display: inline-block;
        @include media(">=md") {
            text-align: center;
            width: 100%;
        }
        li {
            display: inline-block;
            text-align: center;
            a {
                display: inline-block;
                text-align: center;
                color: $main-color-two;
                font-size: 16px;
                width: 36px;
                height: 36px;
                padding-top: 4px;
                border: 2px solid $main-color-two;
                border-radius: 100%;
                text-decoration: none;
                &:hover {
                    color: $white;
                    background-color: $main-color-two;;
                }
            }
        }
    }
}


.no-gutter>[class^="col-"]{
  padding-left: 0;
  padding-right: 0;
}