.callout-text {
    background-color: lighten($main-color-two, 70%);
    border: 5px solid $main-color-two;
    padding: 20px;
    width: 100%;
    max-width: 100%;
    a {
        color: $dk-grey;
        text-decoration: underline;
        &:hover {
            color: $dk-grey;
            text-decoration: none;
        }
    }
    &.alt {
        background-color: $main-color-one;
        color: $white;
        border-color: lighten($main-color-one, 10%);
        a {
            color: $white;
            text-decoration: underline;
            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }
    p {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}