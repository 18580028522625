.carousel-top-stories {
	&.slick-slider {
		margin: 0 -10px !important;
	}
	.slick-track {
		display: flex !important;
	}
	.slick-slide {
		height: auto;
	}
	.slick-prev, .slick-next {
		color: $black;
		background-color: $white;
		width: 50px;
		height: 50px;
		border-radius: 100%;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
	}
	.slick-prev {
		top: calc(50% - 35px);
		left: 5px;
		@include media(">=94.375rem") {
			left: -50px;
		}
	}
	.slick-next {
		top: calc(50% - 35px);
		right: 5px;
		@include media(">=94.375rem") {
			right: -50px;
		}
	}
	.slick-next:before, .slick-prev:before {
		font-size: rem(30);
		font-weight: 700;
		color: $main-color-one;
	}
    .item {
		margin: 10px;
		border: 2px solid $main-color-one;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
		background-color: $main-color-one;
        a {
            display: block;
            // border: 2px solid $main-color-one;
            color: $main-color-one;
            height: 100%;
            .media {
                display: block;
                overflow: hidden;
				height: auto;
				.inner {
					display: block;
					height: 200px;
					background-size: cover;
					background-position: center center;
					transition: all .5s ease-in-out;
                    transform: scale(1);
                    @include media(">=xs" , "<=sm") {
                        height: 250px;
                    }
				}
                img {
                    max-width: 100%;
                    transition: all .5s ease-in-out;
                    transform: scale(1);
                }
            }
            .editorial {
                display: block;
				padding: 20px;
				background-color: $main-color-one;
				color: $white;
                .date {
					display: inline;
					font-size: rem(12);
					font-family: $condensed-font;
					font-weight: 700;
					text-transform: uppercase;
					&:after {
						content: ' | ';
						font-size: rem(16);
					}
				}
				.category {
					display: inline;
					text-transform: uppercase;
					span {
						display: inline-block;
						text-align: left;
						font-size: rem(12);
						font-family: $condensed-font;
						font-weight: 700;
						border-bottom: none;
						padding-bottom: 0;
						margin-bottom: 0;
						&:after {
							content:', '
						}
						&:last-child {
							&:after {
								content: '';
							}
						}
					}
				}
                .title {
                    display: block;
                    font-weight: 700;
                    font-size: rem(18);
					margin-bottom: 10px;
					text-transform: uppercase;
					font-family: $condensed-font;
                }
                .detail {
                    display: block;
                    font-size: rem(14);
					line-height: rem(16);
					@include multiLineEllipsis($lineHeight: 1rem, $lineCount: 2, $bgColor: $main-color-one);  
                }
            }
            &:hover {
				color: $white;
                text-decoration: none;
                .media {
                    .inner {
                        transition: all .5s ease-in-out;
                        transform: scale(1.15);
                    }
				}
				.editorial {
					.title {
						border-bottom: 2px solid $white; 
						margin-bottom: 8px; 
					}
				}
			}
			&:focus {
				outline: dotted 2px $main-color-one;
			}
        }  
	}   
}

.more-news-button {
	text-align: center;
	a {
		display: inline-block;
		border: 2px solid $main-color-one;
		padding: 10px 20px;
		color: $main-color-one;
		font-size: rem(16);
		border-radius: 30px;
		font-weight: 700;
		margin-top: 40px;
		width: auto;
		text-align: center;
		background-color: transparent;
		text-decoration: none !important;
		text-transform: uppercase;
		&:after {
			font-family: $icon-font;
			content: '\f054';
			margin-left: 5px;
		}
		&:hover {
			color: $white;
			background-color: $main-color-one;
			text-decoration: none;
		}
	}
}