.eventtitle {
    background-color: $dk-grey; 
    background-image: url(../images/bg-stripe.svg);
    background-size: cover;
    background-position: center center;
    padding: 20px 0;
	h1 {
		margin: 0;
		padding: 0;
		color: $white;
		font-family: $header-font;
		font-size: rem(30);
        line-height: rem(30);
    }
}


.event-location {
    p {
        margin: 0;
    }
}

.map-btn {
    font-weight: 700;
    color: $cal-linkColor !important;
    text-decoration: underline !important;
    &:hover {
       color: $cal-linkHoverColor !important;
    }
    &:after {
        font-size: inherit;
        font-family: $icon-font;
        content: '\f5a0';
        margin-left: rem(5);
        font-weight: 700;
    }
}

