.badges {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include container;
    .item {
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        a {
            display: block;
            text-align: center;
            img {
                margin: 0 auto;
            }
        }
    }
    &.alt {
        .item {
            width: 120px;
        }
    }
}