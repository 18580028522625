.link-grid {
    background-color: $main-color-one;
    background-image: url(../images/bg-stripe.svg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 60px 0;
    .title {
        display: block;
        font-size: rem(24);
        line-height: rem(26);
        margin-bottom: rem(10);
        font-weight: 700;
        position: relative;
        text-align: center;
        color: $white;
        @include media(">sm") {
            font-size: rem(28);
            line-height: rem(30);
        }
        @include media(">md") {
            font-size: rem(36);
            line-height: rem(38);
        }
    }
    .subtitle {
        display: block;
        font-size: rem(16);
        line-height: rem(18);
        margin-bottom: rem(20);
        position: relative;
        text-align: center;
        margin-bottom: 40px;
        color: $white;
        a {
            color: $white;
            text-decoration: underline;
            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
        @include media(">sm") {
            font-size: rem(18);
            line-height: rem(20);
        }
        @include media(">md") {
            font-size: rem(22);
            line-height: rem(24);
        }
    }
    ul {
        column-count: 1;
        margin: 0;
        padding: 0;
        text-align: center;
        @include media(">=sm") {
            column-count: 2;
        }
        @include media(">=md") {
            column-count: 3;
        }
        > li {
            display: block;
            page-break-inside: avoid;
            break-inside: avoid;
            margin-bottom: 15px;
            a {
                display: block;
                padding: 12px 65px 12px 12px;
                border: 2px solid $white;
                color: $white; 
                background-color: $main-color-one;
                min-width: rem(100);
                text-decoration: none;
                text-align: left;
                position: relative;
                font-size: rem(16);
                line-height: rem(18);
                font-weight: 700;
                text-decoration: none !important;
                width: 100%;
                position: relative;
                border-radius: 30px;
                &:hover {
                    text-decoration: none;
                    background-color: $white;
                    color: $dk-grey;
                    &:after {
                        color: $dk-grey;
                        transition: all .3s ease;
                        padding-left: 14px;
                    }
                }
                &:after {
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    top: calc(50% - 15px);
                    right: 0;
                    font-family: $icon-font;
                    content: '\f054';
                    font-size: 20px;
                    margin-right: 5px;
                    padding-left: 10px;
                    padding-top: 6px;
                    color: $main-color-one;
                    background-color: $white;
                    border-radius: 100%;
                    transition: all .3s ease;
                }
            }
        }
    }
    &.alt {
        background-color: $xlt-grey;
        .title {
            color: $dk-grey;
        }
        .subtitle {
            color: $dk-grey;
            a {
                color: $dk-grey;
                text-decoration: underline;
                &:hover {
                    color: $dk-grey;
                    text-decoration: none;
                }
            }
        }
        ul {
            > li {
                a {
                    background-color: transparent;
                    border-color: $dk-grey;
                    color: $dk-grey;
                    &:after {
                        background-color: $dk-grey;
                        color: $white;
                    }
                    &:hover {
                        background-color: $dk-grey;
                        color: $white;
                        &:after {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    &.alt-two {
        background-color: $dk-grey;
        .title {
            color: $white;
        }
        .subtitle {
            color: $white;
        }
        ul {
            > li {
                a {
                    background-color: transparent;
                    border-color: $white;
                    color: $white;
                    &:after {
                        background-color: $white;
                        color: $dk-grey;
                    }
                    &:hover {
                        background-color: $white;
                        color: $dk-grey;
                        &:after {
                            color: $dk-grey;
                        }
                    }
                }
            }
        }
    }
}