.hm-events {
   padding: 80px 0; 
    .inner {
        @include container;
        h2 {
            // margin-bottom: 20px;
        }
        .hm-event-list {
            margin: 0;
            padding: 0;
            list-style-type: none;
            @include media(">=md") {
                column-count: 2;
            }
            li {
                margin-bottom: 20px;
                display: block;
                page-break-inside: avoid;
                break-inside: avoid;
                a {
                    display: flex;
                    width: 100%;
                    text-decoration: none;
                    .date {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border: 3px solid $main-color-one;
                        min-width: 60px;
                        min-height: 60px;
                        padding: 10px;
                        text-align: center;
                        color: $main-color-two;
                        font-size: rem(20);
                        line-height: rem(20);
                        font-family: $header-font;
                        font-weight: 700;   
                        span {
                            display: block;
                            text-align: center;
                            color: $main-color-two;
                            font-size: rem(30);
                            line-height: rem(30);
                            font-weight: 700;  
                        }
                    }
                    .details {
                        display: block;
                        padding-left: 20px;
                        .title {
                            display: block;
                            text-transform: uppercase;
                            font-size: rem(18);
                            line-height: rem(20);
                            font-weight: 700;
                            color: $main-color-two;
                            // margin-bottom: 10px;
                            @include multiLineEllipsis($lineHeight: 1.25rem, $lineCount: 2, $bgColor: $white);  
                        }
                        .description {
                            display: block;
                            font-size: rem(14);
                            @include multiLineEllipsis($lineHeight: 1rem, $lineCount: 2, $bgColor: $white);  
                            margin: 5px 0;
        
                        }
                        .time {
                            display: block;
                            font-style: italic;
                            font-size: rem(14);
                            line-height: rem(14);
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        .date {
                            text-decoration: none;
                            background-color: $main-color-one;
                            color: $white;
                            span {
                                color: $white;
                            }
                        }
                        .details {
                            .title {
                                color: $main-color-one;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }  
        }
        .all-events {
            border-top: 1px dotted $main-color-one;
            margin-top: 20px;
            padding-top: 20px;
            text-align: center;
            a {
                display: inline-block;
                border: 2px solid transparent;
                padding: 10px 20px;
                color: $white;
                font-size: rem(18);
                font-weight: 700;
                width: auto;
                text-align: center;
                background-color: $main-color-one;
                text-decoration: none !important;
                text-transform: uppercase;
                border-radius: 30px;
                &:after {
                    font-family: $icon-font;
                    content: '\f054';
                    margin-left: 5px;
                }
                &:hover {
                    color: $main-color-one;
                    background-color: $white;
                    text-decoration: none;
                    border-color: $main-color-one;
                }
            }
        }

    }

}