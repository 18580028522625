.carousel-testimonial {
    background-image: url(../images/bg-carousel-1.png);
    background-size: cover;
    background-position: center center;
    position: relative;
    .carousel-testimonial-inner {
        position: relative;
        padding-bottom: 50px;
        padding-top: 15px;
        @include media(">=sm") {
            padding-bottom: 0;
            padding-top: 0;
        }
        .item {
            position: relative;
            min-height: 300px;
            @include media(">=sm") {
                display: flex;
                min-height: rem(400);
                padding: 0 5px;
            }
            .media {
                position: relative;
                height: 100%;
                @include media(">=sm") {
                    flex: 0 0 50%;
                    max-width: 50%;
                    height: 100%; 
                    order: 2;
                }
                .inner {
                    position: absolute;
                    content: '';
                    display: block;
                    overflow: hidden; 
                    top: 0;
                    bottom: 0;
                    width: 110%;
                    @include media(">=sm") {
                        left: -10%;
                    }
                    img {
                        position: absolute;
                        max-height: 150%;
                        max-width: none;
                        width: auto;
                        transform: translate(-50%,-50%);
                        height: auto;
                        min-height: 100%;
                        right: auto;
                        left: 50%;
                        top: 50%;
                        min-width: 100%;
                    }     
                }
            }
            .detail {
                color: $main-color-two;
                position: absolute;
                bottom: 0;
                font-size: 16px;
                line-height: 18px;
                padding: 5px;
                background-color: rgba($white, .75);
                @include media(">=sm") {
                    padding: 30px 30px 30px 60px;
                    background-color: $white;
                    position: relative;
                    flex: 0 0 50%;
                    max-width: 50%;
                    order: 1;
                }
                @include media(">=94.375rem") {
                    padding: 30px;
                }
                .title {
                    font-family: $header-font;
                    font-size: 16px;
                    color: $main-color-one;
                    font-weight: 700;
                    text-transform: uppercase;
                    padding-bottom: 10px;
                    margin-bottom: 10px;                    
                    position: relative;
                    &:before {
                        display: block;
                        content:'';
                        width: 150px;
                        height: 3px;
                        background-color: $main-color-one;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                    @include media(">=sm") {
                        padding-bottom: 20px;
                        margin-bottom: 20px; 
                        font-size: rem(20);
                        line-height: rem(22);
                        font-size: rem(30);
                        line-height: rem(32);
                        &:before {
                            width: 150px;
                            height: 8px;
                        }
                    }        
                }
                .quote {
                    font-size: 14px;
                    line-height: 16px;
                    color: $main-color-one;
                    @include media("<=sm") {
                        @include multiLineEllipsis($lineHeight: 1rem, $lineCount: 2, $bgColor: $white);  
                    }
                    @include media(">=sm") {
                        font-size: rem(18);
                        line-height: rem(20);
                    }
                }
                .more {
                    a {
                        display: inline-block;
                        right: 20px;
                        bottom: 0;
                        border: 2px solid $main-color-one;
                        padding: 10px 20px;
                        color: $main-color-one;;
                        font-size: rem(16);
                        border-radius: 30px;
                        font-weight: 700;
                        margin-top: 20px;
                        width: auto;
                        text-align: center;
                        background-color: $white;
                        text-decoration: none !important;
                        text-transform: uppercase;
                        &:after {
                            font-family: $icon-font;
                            content: '\f054';
                            margin-left: 5px;
                        }
                        &:hover {
                            color: $white;
                            background-color: $main-color-one;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    .slick-prev, .slick-next {
        background-color: $white;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        &:before {
            font-family: $icon-font;
            color: $main-color-one;
            font-size: 30px;
        }
    }
    .slick-prev {
        top: calc(50% - 35px);
        left: 5px;
        @include media(">=94.375rem") {
            left: -50px;
        }
    }
    .slick-next {
        top: calc(50% - 35px);
        right: 5px;
        @include media(">=94.375rem") {
            right: -50px;
        }
    }
    .slick-dots {
        bottom: -5px;
    }
    .slick-dots li button:before {
        color: $white;
    }
}