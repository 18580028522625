.callout-blocks {
    background-color: $xlt-grey;
    background-image: url(../images/bg-stripe.svg);
    background-size: cover;
    background-position: center center;
    padding-top: 60px;
    .inner {
        background: linear-gradient( to bottom, transparent 70%, transparent 70%, $white 30% );
        padding-bottom: 60px;
    }
    .callout-blocks-wrapper {
        @include media(">=md") {
            display: flex;
            flex-wrap: wrap;
            margin: -10px;
            // justify-content: space-between;
        }
        .item {
            background-color: $xdk-grey;
            color: $white;
            padding: 40px;
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
            margin: 10px;
            min-width: rem(280);
            @include media(">=md") {
                // margin-right: 20px;
                flex-grow: 1;
                flex-basis: 0;
                // &:last-child {
                //     margin-right: 0;
                // }
            }
            .head {
                text-transform: uppercase;
                position: relative;
                font-size: rem(18);
                line-height: rem(20);
                font-weight: 700;
                padding-bottom: 20px;
                margin-bottom: 20px;
                @include media(">=md") {
                    font-size: rem(20);
                    line-height: rem(22);
                }
                @include media(">=lg") {
                    font-size: rem(26);
                    line-height: rem(28);
                    padding-bottom: 30px;
                    margin-bottom: 30px;
                }
                &:before {
                    display: block;
                    content:'';
                    width: 150px;
                    height: 4px;
                    background-color: $white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    @include media(">=md") {
                        height: 8px;
                    }
                }
            }
            .detail {
                font-size: rem(16);
                line-height: rem(18);
                a {
                    color: $white;
                    text-decoration: underline;
                    &:hover {
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
            .button {
                text-align: left;
                a {
                    display: inline-block;
                    border: 2px solid $white;
                    color: $white;
                    padding: rem(8) rem(5) rem(8) rem(10);
                    font-size: rem(14);
                    background-color: transparent;
                    font-weight: 700;
                    border-radius: 30px;
                    position: relative;
                    margin: 20px 5px 0 5px;
                    text-decoration: none;
                    &:after {
                        display: inline-block;
                        right: 10px;
                        font-family: $icon-font;
                        content: '\f054';
                        margin-left: 5px;
                        font-weight: 700;
                        background-color: $white;
                        color: $main-color-one;
                        width: rem(20);
                        height: rem(20);
                        border-radius: 100%;
                        padding-left: 7px;
                        transition: all .3s ease;
                    }
                    &:hover {
                        background-color: $white;
                        color: $main-color-one !important;
                        text-decoration: none;
                        font-weight: 700;
                        &:after {
                            padding-left: 11px;
                            transition: all .3s ease;
                        }
                        
                    }
                }
            }
        }
    }
}
