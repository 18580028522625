.carousel-hero {
    position: relative;
    z-index: 2;
    padding-bottom: 60px;
    @include media(">=md") {
        padding-bottom: 0;
    }
	.item {
        background-color: $main-color-two;
        @include media(">=sm") {
            background-color: transparent;
        }
		.inner-wrap {
			position: relative;
			overflow: hidden;
			display: block;
			z-index: 0;
			&:before {
				display: none;
			}
			.media-wrap {
				img {
                    width: 100%;	
                }
            }
            .mask {
                @include media(">=sm") {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    width: 100%;
                    background-color: transparent;
                    background-image: linear-gradient(to right, $white, $white 50%, transparent 50%, transparent 50%,);
                    opacity: .80;
                }
                @include media(">=sm") {
                    background-image: linear-gradient(to right, $white, $white 50%, transparent 50%, transparent 50%,);
                }
            }
            .message {
                display: block;
                position: relative;
                color: $white;
                background-color: $white;
                color: $main-color-one;;
                padding: 20px;
                font-weight: 700;
                z-index: 2;
                margin: 15px 0;
                .title {
                    font-family: $header-font;
                    font-size: rem(20);
                    margin-bottom: rem(15);
                    @include media(">=sm") {
                        font-size: rem(32);
                        line-height: rem(32);
                        padding: 0;
                        text-transform: uppercase;
                        position: relative;
                        padding-right: 30px;
                        max-width: 90%;
                        text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white, 1px 1px 0 $white;
                    }
                    @include media(">=md") {
                        padding: 0 0 20px 0;
                        font-size: rem(52);
                        line-height: rem(52);
                    }
                }
                .detail {
                    display: block;
                    font-size: rem(14);
                    line-height: rem(16);
                    @include media(">=sm") {
                        font-size: rem(16);
                        line-height: rem(18);
                        padding: 10px 0 0 0;
                        position: relative;
                        width: 40%;
                        border-top: 3px solid $main-color-one;
                    }
                    @include media(">=md") {
                        padding: 20px 0 0 0;
                        border-top: 10px solid $main-color-one;
                    }
                }
                a {
                    &.bttn {
                        display: inline-block;
                        right: 20px;
                        bottom: 0;
                        border: 2px solid $main-color-one;
                        padding: 10px 20px;
                        color: $main-color-one;;
                        font-size: rem(16);
                        border-radius: 30px;
                        font-weight: 700;
                        margin-top: 20px;
                        width: auto;
                        text-align: center;
                        background-color: $white;
                        text-decoration: none !important;
                        text-transform: uppercase;
                        &:after {
                            font-family: $icon-font;
                            content: '\f054';
                            margin-left: 5px;
                        }
                        &:hover {
                            color: $white;
                            background-color: $main-color-one;
                            text-decoration: none;
                        }
                    }
                }
                @include media(">=sm") {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    width: 100%;
                    max-width: $container-max-width;
                    margin: 0 auto;
                    font-size: rem(18);
                    line-height: rem(20);
                    background-color: transparent;
                    padding: 0 0 0 50px;
                }
                @include media(">=98.125rem") {
                    padding: 0;
                }
            }
		}
    }
    .slick-dots {
        position: absolute;
        bottom: 0;
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        // @include media(">=sm") {
        //     bottom: 0;
        // }
    }
    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }
}




.carousel-lp-hero {
    position: relative;
    z-index: 2;
    @include media(">=sm") {
        margin-bottom: -30px;
    }
	.item {
        background-color: $main-color-two;
        @include media(">=sm") {
            background-color: transparent;
        }
		.inner-wrap {
			position: relative;
			overflow: hidden;
			display: block;
			z-index: 0;
			&:before {
				display: none;
			}
			.media-wrap {
				img {
                    width: 100%;	
                }
                @include media(">=sm") {
                    img {
                        margin-bottom: 100px;
                    }
                }
			}
			.inner {
				position: relative;
				@include media(">=sm") {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					max-width: rem(1140);
					margin: 0 auto;
				}
				.message {
                    display: block;
					position: relative;
					color: $white;
					background-color: $main-color-two;
					color: $white;
					padding: 20px 20px 60px 20px;
					font-size: rem(14);
					line-height: rem(16);
					font-weight: 700;
					z-index: 2;
					.title {
                        font-family: $header-font;
						font-size: rem(20);
						margin-bottom: rem(15);
					}
					.detail {
						display: block;
					}
					a {
						&.bttn {
                            display: block;
                            
                            right: 20px;
                            bottom: 0;
                            border: 2px solid transparent;
                            padding: 20px;
                            color: $white;
                            font-size: rem(20);
                            font-weight: 700;
                            margin-top: 20px;
                            width: auto;
                            text-align: center;
                            background-color: $main-color-one-alt;
                            text-decoration: none !important;
                            text-transform: uppercase;
							&:after {
								font-family: $icon-font;
								content: '\f054';
								margin-left: 5px;
							}
							&:hover {
								color: $main-color-one;
								background-color: $white;
                                text-decoration: none;
                                border-color: $main-color-one-alt;
							}
						}
					}
					@include media(">=sm") {
						// display: flex;
						position: absolute;
						right: 50px;
						width: 50%;
						font-size: rem(18);
						line-height: rem(20);
						padding: rem(30);
                        bottom: 30px;
						.title {
							font-size: rem(32);
                            line-height: rem(32);
                            padding: 0 0 20px 0;
                            // width: 40%;
                            margin-bottom: 0;
                        }
                        .detail {
                            padding: 0 0 40px 0;
                            // width: 60%;
                            position: relative;
                        }
                        a {
                            &.bttn {
                                position: absolute;
                                bottom: -30px;
                                right: 30px;
                                left: auto;
                            }
                        } 
                    }
				}
			}
		}
    }
    .slick-dots {
        position: absolute;
        bottom: 0;
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        @include media(">=sm") {
            bottom: 35px;
            width: 300px;
        }
    }
    // &.slick-dotted.slick-slider {
    //     margin-bottom: 0;
    // }
}
