.slab {
    position: relative;
    // @import 'components/slabs/cta';
    // @import 'components/slabs/slab_backgrounds';
    @import 'components/slabs/badges';
    @import 'components/slabs/basic_content';
    @import 'components/slabs/callout_blocks';
    @import 'components/slabs/callout_text';
    @import 'components/slabs/card_grid';
    @import 'components/slabs/contacts';
    @import 'components/slabs/hm_events';
    // @import 'components/slabs/icon';
    // @import 'components/slabs/intros';
    // @import 'components/slabs/hm_hero';
    @import 'components/slabs/link_grid';
    @import 'components/slabs/stats';
    @import 'components/slabs/teasers';
    @import 'components/slabs/text_cta';

    @import 'components/slabs/program_finder';
    @import 'components/slabs/visit_tour';
}


// Interior page slab overrides
.content-area {
    .slab {
        .image-teaser, .card-teaser {
            .image-teaser-wrapper, .card-teaser-wrapper {
                .image-teaser-inner, .card-teaser-inner  {
                    .image-teaser-body, .card-teaser-body {
                        padding-right: 20px !important;
                        padding-left: 20px;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }
        }
        .link-grid {
            padding: 20px 0;
            ul {
                @include media(">=md") {
                    column-count: 2;
                }
            }
        }
    }
}