@import 'calendar/_calendar_grid';
@import 'calendar/_calendar_filters';
@import 'calendar/_calendar_controls';
@import 'calendar/_calendar_layout';
@import 'calendar/_calendar_main_list';
@import 'calendar/_calendar_details';
@import 'calendar/_event_lists';

html {
    box-sizing: border-box;
    overflow-x: hidden;
  }

*, ::after, ::before, div {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}


.input-group.date .input-group-addon {
    cursor: pointer
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}