.image-teaser {
	position: relative;
    overflow: hidden;
    background-color: $main-color-one;
    background-image: url(../images/bg-stripe.svg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
	.image-teaser-wrapper {
		position: relative;
		padding: 30px 0 10px 0;

        @include media(">=sm") {
            padding: 0;
        }
		.image-teaser-inner {
			// position: relative;
            display: block;
            @include container;
			@include media(">=sm") {
				flex-flow: row nowrap;
				margin: 0 auto;
				display: -ms-flexbox;
				display: flex;
				justify-content: space-between;
                &.big-bottom {
                    padding-bottom: rem(100);
                }
			}
			.image-teaser-body {
				-webkit-box-flex: 0;
				position: relative;
				z-index: 3;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-flow: column nowrap;
				flex-flow: column nowrap;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
                width: 100%;
                padding-top: 20px;
				@include media(">=sm") {
					min-height: rem(400);
					flex: 0 1 50%;
                    max-width: 50%;
                    padding-top: 0;
					padding-right: 5rem;
				}
				.headgroup {
					color: $white;
					.superhead {
						margin-bottom: 10px;
						font-family: $primary-font;
						font-weight: 700;
						text-transform: uppercase;
						letter-spacing: .15em;
						line-height: 1.5;
						font-size: 0.8125rem;
					}
					.headline {
                        position: relative;
						font-size: rem(26);
						line-height: rem(28);
						font-family: $header-font;
                        text-transform: uppercase;
                        margin-bottom: 40px;
                        &:after {
                            content: '';
                            background-color: $white;
                            width: 200px;
                            height: 5px;
                            position: absolute;
                            bottom: -20px;
                            left: 0;
                        }
					}
				}
				.image-teaser-summary {
					color: $white;
					a {
						color: $white;
						text-decoration: underline;
						&:hover {
							color: $white;
							text-decoration: none;
						}
					}
				}
				.image-teaser-button {
                    margin-top: 20px;
                    position: relative;
                    z-index: 10;
					a {
						border: 2px solid $white;
						color: $white !important;
						padding: rem(8) rem(5) rem(8) rem(10);
						font-size: rem(14);
						background-color: transparent;
                        font-weight: 700;
						border-radius: 30px;
						position: relative;
						text-decoration: none;
						&:after {
							display: inline-block;
							// position: absolute;
							right: 10px;
							font-family: $icon-font;
							content: '\f054';
							margin-left: 5px;
							font-weight: 700;
							background-color: $white;
							color: $main-color-one;
							width: rem(20);
							height: rem(20);
							border-radius: 100%;
							padding-left: 7px;
							transition: all .3s ease;
						}
						&:hover {
							background-color: $white;
							color: $main-color-one !important;
							text-decoration: none;
							font-weight: 700;
							&:after {
								padding-left: 11px;
								transition: all .3s ease;
							}
						}
					}
				}
			}
			.image-teaser-media {
				width: 100%;
				position: relative;
                z-index: 1;
                padding: 20px 0 0 0;
				@include media(">=sm") {
					position: absolute;
					content: '';
					display: block;
                    margin: 0;
                    padding: 0;
					z-index: 0;
					max-width: 50%;
					top: 0;
					bottom: 0;
					left: auto;
					right: 0;
					overflow: hidden;
					z-index: 2;
				}	
				img {
					display: inline-block;
					max-width: 100%;
					vertical-align: middle;
					border-style: none;
					@include media(">=sm") {
						position: absolute;
						// max-height: 110%;
						max-width: none;
						width: auto;
						transform: translate(-50%, -50%);
						height: auto;
						min-height: 100%;
						right: auto;
						left: 50%;
						top: 50%;
						min-width: 100%;
					}
				}
			}
		}
	}
	&.flip {
		.image-teaser-wrapper {
			&:before {
				margin: 0 -750px 0 auto;
			}
			.image-teaser-inner {
				.image-teaser-body {
					order: 2;
					padding-right: 0;
					@include media(">=sm") {
						padding-left: rem(20);
                    }
                    @include media(">=md") {
						padding-left: rem(80);
					}
				}
				.image-teaser-media {
					left: 0;
					right: auto;
				}
			}
		}
	}
	&.alt {
        background-color: $xlt-grey;
        background-image: url(../images/bg-stripe.svg);
		.image-teaser-wrapper {
            .image-teaser-inner {
                .image-teaser-body {
                    // color: $main-color-three;
                    .headgroup {
						color: $main-color-one;
                        .headline {
                            &:after {
                                background-color: $main-color-one;
                            }
                        }
                    }
                    .image-teaser-summary {
						color: $main-color-one;
						a {
							color: $main-color-one;;
							text-decoration: underline;
							&:hover {
								color: $main-color-one;;
								text-decoration: none;
							}
						}
                    }
                    .image-teaser-button {
                        margin-top: 20px;
                        a {
                            border: 2px solid $main-color-one;
                            color: $main-color-one !important;
                            padding: rem(8) rem(5) rem(8) rem(10);
                            font-size: rem(14);
                            background-color: transparent;
                            font-weight: 700;
							&:after {
								display: inline-block;
								// position: absolute;
								right: 10px;
								font-family: $icon-font;
								content: '\f054';
								margin-left: 5px;
								font-weight: 700;
								background-color: $main-color-one;
								color: $white;
								width: rem(20);
								height: rem(20);
								border-radius: 100%;
								padding-left: 7px;
								transition: all .3s ease;
							}
                            &:hover {
                                background-color: $main-color-one;
                                color: $white !important;
                                text-decoration: none;
								font-weight: 700;
								&:after {
									padding-left: 10px;
									transition: all .3s ease;
								}
                            }
                        }
                    }
                }
            }
		}
	}
	&.alt-two {
        background-color: $dk-grey;
        background-image: url(../images/bg-stripe.svg);
		.image-teaser-wrapper {
            .image-teaser-inner {
                .image-teaser-body {
                    // color: $main-color-three;
                    .headgroup {
						color: $white;
                        .headline {
                            &:after {
                                background-color: $white;
                            }
                        }
                    }
                    .image-teaser-summary {
                        color: $white;
                    }
                    .image-teaser-button {
                        margin-top: 15px;
                        a {
                            border: 2px solid $white;
                            color: $white!important;
                            padding: rem(8) rem(5) rem(8) rem(10);
                            font-size: rem(14);
                            background-color: transparent;
                            font-weight: 700;
							&:after {
								display: inline-block;
								// position: absolute;
								right: 10px;
								font-family: $icon-font;
								content: '\f054';
								margin-left: 5px;
								font-weight: 700;
								background-color: $white;
								color: $main-color-one;
								width: rem(20);
								height: rem(20);
								border-radius: 100%;
								padding-left: 7px;
								transition: all .3s ease;
							}
                            &:hover {
                                background-color: $white;
                                color: $dk-grey !important;
                                text-decoration: none;
								font-weight: 700;
								&:after {
									padding-left: 10px;
									transition: all .3s ease;
								}
                            }
                        }
                    }
                }
            }
		}
    }
}


.card-teaser {
	position: relative;
    overflow: hidden;
	padding-top: 60px;
	background: linear-gradient(to bottom, $xlt-grey 70%, $xlt-grey  70%,#fff 30%);
	.card-teaser-wrapper {
		position: relative;
		padding: 30px 0 10px 0;
		@include container;
		@include media("<=91.875rem") {
			padding: 0;
		}
		.card-teaser-inner {
			// position: relative;
            display: block;
            @include container;
			@include media(">=sm") {
				flex-flow: row nowrap;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
			}
			@include media("<=91.875rem") {
				padding: 0;
			}
			.card-teaser-body {
				-webkit-box-flex: 0;
				position: relative;
				z-index: 3;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-flow: column nowrap;
				flex-flow: column nowrap;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
                width: 100%;
                padding: 20px;
                background-color: $main-color-one;
				@include media(">=sm") {
					min-height: rem(400);
					flex: 0 1 50%;
                    max-width: 50%;
					padding-left: rem(50);
				}
				.headgroup {
					color: $white;
					.superhead {
						margin-bottom: 10px;
                        font-family: $primary-font;
						font-weight: 700;
						text-transform: uppercase;
						letter-spacing: .15em;
						line-height: 1.5;
						font-size: 0.8125rem;
					}
					.headline {
                        position: relative;
						font-size: rem(26);
						line-height: rem(28);
                        font-family: $header-font;
                        text-transform: uppercase;
                        margin-bottom: 40px;
                        &:after {
                            content: '';
                            background-color: $white;
                            width: 200px;
                            height: 5px;
                            position: absolute;
                            bottom: -20px;
                            left: 0;
                        }
					}
				}
				.card-teaser-summary {
					color: $white;
					a {
						color: $white;
						text-decoration: underline;
						&:hover {
							color: $white;
							text-decoration: none;
						}
					}
                }
				.card-teaser-button {
                    margin-top: 15px;
                    position: relative;
                    z-index: 10;
					a {
						border: 2px solid $white;
						color: $white;
						padding: rem(8) rem(5) rem(8) rem(10);
						font-size: rem(14);
						background-color: transparent;
						font-weight: 700;
						border-radius: 30px;
						position: relative;
						margin: 0 5px;
						text-decoration: none;
						&:after {
							display: inline-block;
							// position: absolute;
							right: 10px;
							font-family: $icon-font;
							content: '\f054';
							margin-left: 5px;
							font-weight: 700;
							background-color: $white;
							color: $main-color-one;
							width: rem(20);
							height: rem(20);
							border-radius: 100%;
							padding-left: 7px;
							transition: all .3s ease;
						}
						&:hover {
							background-color: $white;
							color: $main-color-one !important;
							text-decoration: none;
							font-weight: 700;
							&:after {
								padding-left: 11px;
								transition: all .3s ease;
							}
							
						}
					}
				}
			}
			.card-teaser-media {
				width: 100%;
				position: relative;
                z-index: 1;
                padding: 20px 0 0 0;
				@include media(">=sm") {
					position: absolute;
					content: '';
					display: block;
                    margin: 0;
                    padding: 0;
					z-index: 0;
					max-width: 50%;
					top: 0;
					bottom: 0;
					left: auto;
					right: 0;
					overflow: hidden;
					z-index: 2;
				}	
				img {
					display: inline-block;
					max-width: 100%;
					vertical-align: middle;
					border-style: none;
					@include media(">=sm") {
						position: absolute;
						// max-height: 110%;
						max-width: none;
						width: auto;
						transform: translate(-50%, -50%);
						height: auto;
						min-height: 100%;
						right: auto;
						left: 50%;
						top: 50%;
						min-width: 100%;
					}
				}
			}
		}
	}
	&.flip {
		.card-teaser-wrapper {
			&:before {
				margin: 0 -750px 0 auto;
			}
			.card-teaser-inner {
				.card-teaser-body {
					order: 2;
					// padding-right: 0;
					@include media(">=sm") {
						padding-left: rem(20);
                    }
                    @include media(">=md") {
						padding-left: rem(50);
					}
				}
				.card-teaser-media {
					left: 0;
					right: auto;
				}
			}
		}
	}
	&.alt {
		background: linear-gradient(to bottom, $dk-grey 70%, $dk-grey  70%,#fff 30%);
		.card-teaser-wrapper {
			.card-teaser-inner {
				.card-teaser-body {
					background-color: $xlt-grey;
					.headgroup {
						color: $dk-grey;
						.superhead {
						}
						.headline {
							&:after {
								background-color: $dk-grey;
							}
						}
					}
					.card-teaser-summary {
						color: $dk-grey;
						a {
							color: $dk-grey;
							text-decoration: underline;
							&:hover {
								color: $dk-grey;
								text-decoration: none;
							}
						}
					}
					.card-teaser-button {
						a {
							border-color: $dk-grey;
							color: $dk-grey;
							&:hover {
								color: $white !important;
								background-color: $dk-grey;
							}
							&:after {
								background-color: $dk-grey;
								color: $white;
							}
						}
					}
				}
			}
		}
	}
	&.alt-two {
		background: linear-gradient(to bottom, $main-color-one 70%, $main-color-one 70%,#fff 30%);
		.card-teaser-wrapper {
			.card-teaser-inner {
				.card-teaser-body {
					background-color: $dk-grey
				}
			}
		}
	}
}