.searchContainer {
    display: block;
    position: relative;
    background-color: $white;
    overflow: hidden;
    padding: 1px 5px;
    border: solid 3px $lt-grey;
    margin-bottom: 10px;
    @include media(">=md") {
        display: inline-flex;
        flex: 1 1 300px;
        width: 260px;
        position: absolute;
        right: 15px;
        top: rem(-46);
        border-radius: 30px;
    }
    @include media(">=91.875rem") {
        right: 0;
    }
    .searchIcon {
            padding: 0.5rem;
            position: absolute;
            top: 0;
            left: 0;
            @include media(">=md") {

            }
        }
    .searchBox {
        height: rem(30);
        border: none;
        padding: 0.5rem 0.5rem 0.5rem 0;
        flex: 1;
        padding-left: 30px !important;
        padding-right: 5px;
        width: 100%;
        @include media(">=md") {
            padding-left: 0;
        }
        &:focus {
            outline: none;
        }
    }
}
