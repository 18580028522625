.contacts {
	text-align: left;
    color: $white;
    background-color: $main-color-one;
    // background-image: url(../images/bg-stripe.svg);
    // background-size: cover;
    // background-position: center center;
    padding: 20px;
    .inner-wrap {
        @include container;
        h2 {
            border-bottom: none;
            text-align: left;
            margin-top: 0;
            color: $white;
            &:after {
                background-color: $white;
            }
        }
        .inner {
            justify-content: space-between;
            @include media(">=sm") {
                display: flex;
                flex-wrap: wrap;
            }
            .item {
                position: relative;
                width: auto;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
                @include media(">=sm") {
                    max-width: 33%;
                }
                .title {
                    font-size: rem(22);
                    line-height: rem(24);
                    font-weight: 700;
                }
                .subtitle {
                    font-size: rem(16);
                    line-height: rem(18);
                    font-weight: 700;
                }
                .details {
                    margin-top: rem(30);
                    position: relative;
                    &:after {
                        position: absolute;
                        top: rem(-12);
                        width: 100px;
                        height: 3px;
                        content: '';
                        background: repeating-linear-gradient(
                            90deg,
                            $white,
                            $white 3px,
                            transparent 3px,
                            transparent 8px
                          );
                    }
                    a {
                        color: $white;
                        text-decoration: underline;
                        &:hover {
                            color: $xlt-grey;
                        }
                    }
                }
            }
        }
    }
}