#calendar {
    line-height: normal;
    ol, ul, li {
        list-style: none;
        padding: 0;
        margin: 0
    }
    .cal-header {
        margin: 20px 0 10px 0;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        li {
            border: none;
            text-align: center;
            -webkit-flex: auto;
            -ms-flex: auto;
            flex: auto;
            width: 13.28%;
            overflow: hidden;
            font-weight: 700
        }
    }
    ol {
        &.calendar-ol {
            border: 1px solid $cal-border-color;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            li {
                // overflow: hidden;
                &.day {
                    border: solid .5px $cal-border-color;
                    background-color: $white;
                    padding: 0;
                    min-height: rem(150);
                    height: auto;
                    width: 13.28571%;
                    -webkit-flex: auto;
                    -ms-flex: auto;
                    flex: auto;
                    overflow: visible;
                    position: relative;
                    &.today {
                        background-color: $xlt-grey;
                        border: dotted 3px $cal-todayColor;
                    }
                    ol {
                        font-weight: 400;
                        line-height: 1;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-flex-flow: column wrap;
                        flex-flow: column wrap;
                        justify-content: space-evenly;
                        li {
                            color: $grey;
                            padding: 5px;
                            height: auto;
                            position: relative;
                            width: 100%;
                            left: 0px;
                            padding: 3px 5px;
                            border-width: 1px 0;
                            border-style: solid;
                            overflow: hidden;
                            border: none;
                            &.first-day {
                                width: 100%;
                                overflow: visible;
                                &.multi-span-3 {
                                    a {
                                        width: 320%;
                                    }
                                }
                                &.multi-span-4 {
                                    a {
                                        width: 430%;
                                    }
                                }
                                &.multi-span-5 {
                                    a {
                                        width: 545%;
                                    }
                                }
                                &.multi-span-6 {
                                    a {
                                        width: 650%;
                                    }
                                }
                                &.multi-span-7 {
                                    a {
                                        width: 760%;
                                    }
                                }
                                &.row-last-day {
                                    a {
                                        width: 100%;
                                    }
                                }
                                a {
                                    width: 210%;
                                    overflow: hidden;
                                    display: block;
                                    text-overflow: ellipsis;
                                }
                            }
                            &.multi-day {
                                background-color: $cal-multiDayBg;
                                height: rem(22);
                                white-space: nowrap;
                                margin: rem(5) 0;
                                padding: rem(6) rem(5);
                                width: 101%;
                                &.extension {
                                    color: transparent;
                                    a {
                                        color: transparent;
                                    }
                                }
                            }
                            .time {
                                display: none;
                            }
                            a {
                                display: inline-block;
                                text-decoration: none;
                                color: $cal-linkColor;
                                font-family: inherit;
                                font-size: rem(12) !important;
                                line-height: rem(12) !important;
                                font-weight: 700;
                                &:hover {
                                    text-decoration: underline;
                                    color: $cal-linkHoverColor;
                                }
                            }
                        }
                    }
                    .dayNum {
                        display: block;
                        width: 100%;
                        color: #000;
                        font-weight: 700;
                        font-size: rem(12);
                        padding: rem(2) 0 rem(2) rem(5);
                    }
                    &.otherMonth {
                        background: #ebebeb url(../assets/images/line-texture-bg-overlay.png) 50%/7px auto repeat;
                        .dayNum {
                            display: block;
                            width: 100%;
                            font-size: rem(12);
                            padding: rem(2) 0 rem(2) rem(5);
                            color: $cal-dayNum;
                            font-weight: 400;
                        }
                        > {
                            a {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.noresults {
    padding: rem(20);
    font-size: rem(22);
    font-weight: 700;
}




// GENERIC CALENDAR LAYOUT STYLES
.listOnly {
    display: none;
}

.list-cal .listOnly {
    display: inherit;
}

.list-cal span.listOnly {
    display: inline;
}

.list-cal .calOnly {
    display: none;
}

.calSearchContainer {
    display: block;
    position: relative;
    background-color: $white;
    overflow: hidden;
    padding: 1px 5px;
    border: solid 3px $lt-grey;
    margin-bottom: 10px;
    @include media(">=md") {
        display: inline-flex;
        flex: 1 1 300px;
        width: 260px;
        // position: absolute;
        // right: rem(15);
        // top: rem(-46);
        border-radius: 30px;
    }
    .searchIcon {
            padding: 0.5rem;
            position: absolute;
            top: 0;
            left: 0;
            @include media(">=md") {

            }
        }
    .searchBox {
        height: rem(30);
        border: none;
        padding: 0.5rem 0.5rem 0.5rem 0;
        flex: 1;
        padding-left: 30px !important;
        padding-right: 5px;
        width: 100%;
        @include media(">=md") {
            padding-left: 0;
        }
        &:focus {
            outline: none;
        }
    }
}


.page-content {
    // padding: 60px 0;
}