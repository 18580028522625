// PROJECT COLORS

// Brand Colors
$main-color-one: #450012;
$main-color-one-alt: #d3430d;
$main-color-two: #045678;
$main-color-three: #a4b3c9;
// $main-color-two: #0c2340;

$hilite-color-one: #cacaca;
$hilite-color-two: #acc6d8;



// Greyscale colors
$white: #fff;
$black: #000;
$rgb-white: "255,255,255" !default;
$rgb-black: "0,0,0" !default;
$xlt-grey: #eeeeee;
$lt-grey: #d9d6d6;
$grey: #757575;
$dk-grey: #332c2c;

$main-color-five: blue;
$hilite-color: yellow;


$text-color: $dk-grey;


// Link & button colors
$color-link: darken($main-color-one, 10%);
$color-link-hover: $main-color-two;
$color-link-alt: $main-color-two;
$color-link-alt-hover: lighten($main-color-two, 10%);
$color-button: $main-color-two;
$color-button-hover: darken($main-color-two, 20%);
$color-button-alt: $main-color-two;
$color-button-alt-hover: lighten($main-color-two, 20%);

// Sidebar nav colors
$sidebar-nav-color-one: $main-color-one;
$sidebar-nav-color-two: $main-color-two;
// Alert colors
$color-alert: #003c71;
$color-alert-emergency: #ea002b;



$xdk-grey: #181818;

/* Fonts */
$primary-font: 'Open Sans', sans-serif;
$nav-font: 'Nunito Sans', sans-serif;
$header-font: 'Nunito Sans', sans-serif;
$condensed-font: 'Open Sans Condensed', sans-serif;
$icon-font: 'Font Awesome 5 Free';


// Slick Slider Inputs
$slick-font-path: "./fonts/" !default;
$slick-font-family: "Font Awesome 5 Free" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $white !default;
$slick-dot-color: $main-color-one !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f053" !default;
$slick-next-character: "\f054" !default;
$slick-dot-character: "\f111" !default;
$slick-dot-character-active: "\f111" !default;
$slick-dot-size: 22px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;


$container-max-width: 90rem;



$nr-color-one: $main-color-one;
$nr-color-two: $main-color-two;
$nr-dk-grey: #333;
$nr-xlt-grey: #efefef;
$nr-categoryColor: $black;
$nr-categoryColorHover: $black;
$nr-categoryColorActive: $white;
$nr-categoryBgColor: transparent;
$nr-categoryBgColorHover: #efefef;
$nr-categoryBgColorActive: $nr-color-one;
$nr-border-radius: 20px;
$nr-nav-color-one: $nr-color-one;
$nr-nav-color-one-hover: $nr-color-two;
$nr-nav-color-two: $nr-color-two;
$nr-text-color: rgb(36, 36, 36);


$cal-color-one: #450012;
$cal-color-two: #045678;
$cal-border-color: $cal-color-two;
$cal-dayNum: $black;
$cal-todayColor: $cal-color-one;
$cal-otherMonthBg: $xlt-grey;
$cal-multiDayBg: lighten($cal-color-one, 65%);
$cal-otherMonthDayNum: $lt-grey;
$cal-linkColor: $cal-color-one;
$cal-linkHoverColor: $cal-color-two;
$cal-filterColor: $black;
$cal-filterColorHover: $black;
$cal-filterColorActive: $white;
$cal-filterBgColor: transparent;
$cal-filterBgColorHover: $xlt-grey;
$cal-filterBgColorActive: $cal-color-one;
$cal-border-radius: 20px;
