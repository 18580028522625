.program-finder {
    background-color: $main-color-one;
    background-image: url(../images/bg-stripe.svg);
    background-size: cover;
    background-position: top center;
    padding: 60px 0;
    .inner {
        @include media(">=md") {
            display: flex;
        }
        @include media(">=91.875rem") {
            padding-right: 0;
            padding-left: 0;
        }
        .intro {
            text-align: center;
            @include media(">=md") {
                flex: 0 0 50%;
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: left;
                padding-right: 20px;
            }
            .headline-group {
                width: 100%;
                font-family: $header-font;
                color: $white;
                margin-bottom: 20px;
                @include media(">=md") {
                    margin-bottom: 0;
                }
                .superhead {
                    display: block;
                    font-size: rem(22);
                    line-height: rem(24);
                    font-weight: 700;
                    margin-bottom: 10px;
                    @include media(">=md") {
                        font-size: rem(32);
                        line-height: rem(34);
                    }
                }
                .head {
                    display: block;
                    font-size: rem(30);
                    line-height: rem(32);
                    font-weight: 700;
                    @include media(">=md") {
                        font-size: rem(46);
                        line-height: rem(48);
                    }
                }
                .link {
                    display: block;
                    color: $white;
                    font-size: rem(16);
                    font-weight: 400;
                    @include media(">=md") {
                        font-size: rem(18);
                        line-height: rem(20);
                    }
                    a {
                        font-weight: 700;
                        color: $white;
                        font-size: rem(18);
                        text-decoration: underline;
                        @include media(">=md") {
                            font-size: rem(20);
                            line-height: rem(22);
                        }
                    }
                }
            }
        }
        .form {
            width: 100%;
            border: 5px solid $white;
            padding: 20px;
            text-align: center;
            @include media(">=md") {
                // flex: 0 0 50%;
                width: 50%;
                text-align: left;
            }
            label {
                color: $white;
                font-size: rem(20);
                font-weight: 700;
                display: inline-block;
            }
            select {
                height: rem(40);
                margin: 0 0 1rem;
                padding: .5rem;
                width: 100%;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border: 1px solid $lt-grey;
                border-radius: 0;
                background-color: $white;
                font-family: inherit;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #141414;
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28119,119,119%29'></polygon></svg>");
                background-origin: content-box;
                background-position: right -1rem center;
                background-repeat: no-repeat;
                background-size: 9px 6px;
                padding-right: 1.5rem;
                -webkit-transition: border-color .25s ease-in-out, -webkit-box-shadow .5s;
                transition: border-color .25s ease-in-out, -webkit-box-shadow .5s;
                transition: box-shadow 0.5s, border-color .25s ease-in-out;
                transition: box-shadow 0.5s, border-color .25s ease-in-out, -webkit-box-shadow .5s
            }
            select:focus {
                outline: none;
                border: 1px solid #777;
                background-color: #fff;
                -webkit-box-shadow: 0 0 5px #aaa;
                box-shadow: 0 0 5px #aaa;
                -webkit-transition: border-color .25s ease-in-out, -webkit-box-shadow .5s;
                transition: border-color .25s ease-in-out, -webkit-box-shadow .5s;
                transition: box-shadow 0.5s, border-color .25s ease-in-out;
                transition: box-shadow 0.5s, border-color .25s ease-in-out, -webkit-box-shadow .5s
            }
            .button, .pagination ul li a, button, input[type=button], input[type=reset], input[type=submit] {
                transition: all .2s ease-out;
                display: inline-flex;
                align-items: center;
                padding: .875rem 1.375rem;
                cursor: pointer;
                font-family: $condensed-font;
                font-size: rem(18);
                line-height: rem(18);
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 700;
                background-color: $lt-grey;
            }
        }
    }
}
